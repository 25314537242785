import { Field, useField } from "formik"
import clsx from "clsx"
import { FieldLabel } from "~/components/FieldLabel"
import { FieldError } from "~/components/FieldError"

interface RadioButtonOptions {
  label: string
  value: string | boolean
}

interface RadioButtonGroupProps {
  label: string
  name: string
  className?: string
  options: RadioButtonOptions[]
  layout?: "vertical" | "horizontal"
  disabled?: boolean
}

export const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const {
    label,
    name,
    options,
    className,
    disabled,
    layout = "horizontal",
  } = props
  const [field, meta, helpers] = useField(name)

  return (
    <div className={className}>
      <FieldLabel label={label} />
      <div className={clsx("flex", layout === "vertical" && "flex-col")}>
        {options.map((option, index) => {
          return (
            <div
              key={option.label}
              className={clsx(
                index !== 0 && layout === "horizontal" && "ml-8",
                meta.touched && meta.error && "text-red-700",
              )}
            >
              <label>
                <Field
                  type="radio"
                  name={name}
                  id={option.label}
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={() => helpers.setValue(option.value)}
                  disabled={disabled}
                />
                <span className="ml-3">{option.label}</span>
              </label>
            </div>
          )
        })}
      </div>

      {meta.touched && meta.error && <FieldError message={meta.error} />}
    </div>
  )
}
