import { useNavigate } from "react-router-dom"
import { FormikHelpers } from "formik"
import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { useGraphQLMutation } from "~/graphql/graphql-hook"
import { adminUsersPath } from "~/util/paths"
import { displayErrors } from "~/util/validations"
import { AdminUserForm, UserValues } from "~/components/Admin/AdminUserForm"
import { Breadcrumb } from "~/components/Breadcrumb"

const CREATE_USER_MUTATION = graphql(`
  mutation CreateUserMutation($input: UserCreateInput!) {
    userCreate(input: $input) {
      user {
        id
      }
    }
  }
`)

export const AdminUserCreateRoute = () => {
  const navigate = useNavigate()
  const userMutation = useGraphQLMutation(CREATE_USER_MUTATION)

  const initialValues: UserValues = {
    email: "",
    name: "",
    companyName: "",
    jobTitle: "",
    bio: "",
    isAdmin: false,
    costPerResponseInDollars: 100,
    costPerConsultationHourInDollars: 1000,
    minResponses: 10,
    maxResponses: 15,
    maxQuestionDurationInHours: 48,
    allowOneOnOneRequests: true,
    allowPrivateQuestions: true,
    canAskQuestions: false,
  }

  const onSubmit = (
    values: UserValues,
    { setFieldError, setSubmitting }: FormikHelpers<UserValues>,
  ) => {
    userMutation.mutate(
      {
        input: {
          userInput: values,
        },
      },
      {
        onSuccess: () => {
          toast.success("User created")
          navigate(adminUsersPath)
        },
        onError: (error) => displayErrors(error, setFieldError),
        onSettled: () => setSubmitting(false),
      },
    )
  }

  return (
    <div className="max-w-3xl m-auto">
      <Breadcrumb path={adminUsersPath} label="Users" />
      <div className="my-8">
        <h1>New User</h1>
      </div>
      <AdminUserForm initialValues={initialValues} onSubmit={onSubmit} />
    </div>
  )
}
