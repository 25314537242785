import clsx from "clsx"
import React from "react"

export const ActionButton = ({
  title,
  onClick,
  disabled = false,
}: {
  title: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabled: boolean
}) => {
  return (
    <button
      type="button"
      className={clsx(
        "border-0",
        "py-1 px-2",
        "rounded-sm",
        "bg-pink-500",
        "text-white",
        "hover:underline",
        "disabled:bg-gray-400",
        "disabled:cursor-not-allowed",
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  )
}
