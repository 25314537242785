// https://github.com/dotansimha/graphql-code-generator/blob/5829dd9b0ea4be5479eeeb2de65307b0e5255352/examples/react/tanstack-react-query/src/use-graphql.ts
// https://the-guild.dev/graphql/codegen/plugins/presets/preset-client
// https://the-guild.dev/graphql/codegen/docs/guides/react-vue
import { type TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  useQuery,
  type UseQueryResult,
  useMutation,
  type UseMutationResult,
} from "@tanstack/react-query"
import request from "graphql-request"

const endpoint = "/graphql"
const csrfMeta = document.querySelector("meta[name=csrf-token]")
const csrf = csrfMeta instanceof HTMLMetaElement ? csrfMeta.content : null

export interface GraphQLError {
  response: {
    errors: ErrorsEntity[]
    status: number
  }
}

interface ErrorsEntity {
  message: string
  extensions: Record<string, unknown>
}

export function useGraphQLQuery<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
): UseQueryResult<TResult> {
  return useQuery(
    [(document.definitions[0] as any).name.value, variables],
    async ({ queryKey }) =>
      request(endpoint, document, queryKey[1] ? queryKey[1] : undefined, {
        ...(csrf && { "X-CSRF-Token": csrf }),
      }),
  )
}

export function useGraphQLMutation<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
): UseMutationResult<TResult, GraphQLError, TVariables, unknown> {
  return useMutation(
    [(document.definitions[0] as any).name.value],
    async (variables: TVariables) =>
      request(endpoint, document, variables ? variables : undefined, {
        ...(csrf && { "X-CSRF-Token": csrf }),
      }),
  )
}
