import { useEffect, useRef } from "react"
import { useParams, Link } from "react-router-dom"
import { graphql } from "~/__generated__"
import { useGraphQLMutation, useGraphQLQuery } from "~/graphql/graphql-hook"
import { Button } from "~/components/Button"
import { FancyRadioButton } from "~/components/FormFields/FancyRadioButton"
import { questionsPath, newQuestionPath } from "~/util/paths"
import { displayErrors } from "~/util/validations"
import { assert } from "~/util/assert"
import loadingGif from "~/images/loading.gif"

const SUGGESTION_THRESHOLD_MS = 15_000

export const PickSuggestionsRoute = () => {
  const { questionId } = useParams()
  assert(questionId)

  const questionResult = useGraphQLQuery(NEW_QUESTION_DOCUMENT, {
    id: questionId,
  })
  const suggestionsResult = useGraphQLQuery(
    QUESTION_SUGGESTIONS_QUERY_DOCUMENT,
    {
      questionId,
    },
  )
  const acceptRejectMutation = useGraphQLMutation(
    SUGGESTION_ACCEPT_REJECT_MUTATION,
  )
  const completedPicksMutation = useGraphQLMutation(
    REQUESTER_COMPLETED_SUGGESTION_PICKS_MUTATION,
  )

  const completedAtRef = useRef()
  completedAtRef.current = questionResult.data?.question.screeningCompletedAt

  const questionRequestsCountRef = useRef<number | undefined>()
  questionRequestsCountRef.current =
    suggestionsResult.data?.questionRequests.nodes.length

  const shouldRefetchSuggestions = (pollingStartTime: number) => {
    return (
      Date.now() - pollingStartTime > SUGGESTION_THRESHOLD_MS &&
      !questionRequestsCountRef.current &&
      completedAtRef.current
    )
  }

  const refetchPoll = (pollingStartTime: number) => {
    const _refetchPoll = () => {
      setTimeout(() => {
        if (shouldRefetchSuggestions(pollingStartTime)) {
          suggestionsResult.refetch()
        } else if (!completedAtRef.current) {
          questionResult.refetch()
        }

        _refetchPoll()
      }, 1000)
    }

    _refetchPoll()
  }

  useEffect(() => {
    refetchPoll(Date.now())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (suggestionsResult.status === "error" || questionResult.status === "error")
    return <div>error</div>
  if (
    suggestionsResult.status === "loading" ||
    questionResult.status === "loading"
  )
    return <div>loading …</div>

  const { nodes: suggestions } = suggestionsResult.data.questionRequests
  const { question } = questionResult.data

  const submitAcceptReject = ({
    requestId,
    hasAccepted,
    target,
  }: {
    requestId: string
    hasAccepted: boolean
    target: HTMLInputElement
  }) => {
    acceptRejectMutation.mutate(
      { input: { requestId, hasAccepted } },
      {
        onError: (error) => {
          displayErrors(error)
          target.checked = false
        },
      },
    )
  }

  const submitCompletedPicks = () => {
    completedPicksMutation.mutate(
      { input: { questionId } },
      {
        onError: (error) => displayErrors(error),
      },
    )
  }

  if (question.userPickedSuggestionsAt || completedPicksMutation.isSuccess)
    return (
      <div className="max-w-3xl m-auto">
        <h1>Thanks for submitting your question</h1>
        <p className="my-8">
          We will be reviewing the question and sending to relevant people for
          response immediately. You will get an email with responses as they
          come in. While you wait for responses you can …
        </p>
        <div>
          &gt; <Link to={questionsPath}>View your questions</Link>
          <br />
          &gt; <Link to={newQuestionPath}>Ask another question</Link>
        </div>
      </div>
    )

  return (
    <div>
      <h1>Request Suggestions</h1>
      <p className="mt-4 flex">
        Optionally select from the following list to help inform who to ask.
      </p>
      {!suggestions.length ? (
        <p className="mt-8 flex">
          Finding people to answer your question{" "}
          <img src={loadingGif} width={24} className="ms-4" alt="loading" />
        </p>
      ) : (
        <>
          <div className="flex gap-4 mt-8 pb-4 border-b">
            <strong className="w-[72px]">Request?</strong>
            <div className="flex flex-1">
              <strong className="md:basis-1/3">
                Name <span className="md:hidden">& Why</span>
              </strong>
              <strong className="flex-1 hidden md:block">Why</strong>
            </div>
          </div>
          {suggestions.map((suggestion) => (
            <div key={suggestion.id} className="flex gap-4 py-4 border-b">
              <div className="w-[72px]">
                <FancyRadioButton
                  label="Y"
                  name={`acceptReject-${suggestion.id}`}
                  checkedColor="green"
                  onChange={({ target }) =>
                    submitAcceptReject({
                      requestId: suggestion.id,
                      hasAccepted: true,
                      target,
                    })
                  }
                />
                <FancyRadioButton
                  label="N"
                  name={`acceptReject-${suggestion.id}`}
                  checkedColor="red"
                  onChange={({ target }) => {
                    submitAcceptReject({
                      requestId: suggestion.id,
                      hasAccepted: false,
                      target,
                    })
                  }}
                />
              </div>
              <div className="md:flex flex-1">
                <div className="basis-1/3">
                  {suggestion.identityProfile.name && (
                    <strong>{suggestion.identityProfile.name}</strong>
                  )}
                  {suggestion.identityProfile.jobTitle && (
                    <div>{suggestion.identityProfile.jobTitle}</div>
                  )}
                  {suggestion.identityProfile.companyName && (
                    <div>{suggestion.identityProfile.companyName}</div>
                  )}
                </div>
                <div className="flex-1 mt-4 md:mt-0 text-sm md:text-base">
                  {suggestion.aiScreeningReasoning}
                </div>
              </div>
            </div>
          ))}
          <div className="mt-8">
            <Button onClick={submitCompletedPicks} label="Finished" />
          </div>
        </>
      )}
    </div>
  )
}

export const NEW_QUESTION_DOCUMENT = graphql(`
  query NewQuestionDocument($id: ID!) {
    question(id: $id) {
      id
      screeningCompletedAt
      userPickedSuggestionsAt
      targetingPositive
      targetingNegative
    }
  }
`)

const QUESTION_SUGGESTIONS_QUERY_DOCUMENT = graphql(`
  query QuestionSuggestionsQueryDocument($questionId: ID!) {
    questionRequests(questionId: $questionId) {
      nodes {
        id
        aiScreeningReasoning
        sources
        identityProfile {
          id
          name
          bio
          companyName
          jobTitle
        }
      }
    }
  }
`)

const SUGGESTION_ACCEPT_REJECT_MUTATION = graphql(`
  mutation SuggestionAcceptReject($input: SuggestionAcceptRejectInput!) {
    suggestionAcceptReject(input: $input) {
      questionRequest {
        id
      }
    }
  }
`)

const REQUESTER_COMPLETED_SUGGESTION_PICKS_MUTATION = graphql(`
  mutation RequesterCompletedSuggestionPicks(
    $input: RequesterCompletedSuggestionPicksInput!
  ) {
    requesterCompletedSuggestionPicks(input: $input) {
      question {
        id
        userPickedSuggestionsAt
      }
    }
  }
`)
