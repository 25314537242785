import React from "react"
import clsx from "clsx"

type FieldWithDescriptionProps = {
  description?: string
  children: React.ReactNode
  hasLabel?: boolean
  className?: string
}

export const FieldWithDescription = ({
  description,
  hasLabel = true,
  children,
  className,
}: FieldWithDescriptionProps) => {
  return (
    <div className={clsx("md:flex items-start gap-8 w-full", className)}>
      <div className="md:w-2/3">{children}</div>
      <div className={clsx("md:w-1/3 text-gray-400", hasLabel && "md:mt-6")}>
        {description}
      </div>
    </div>
  )
}
