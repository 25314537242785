import { useParams } from "react-router-dom"
import { Formik, Form, FormikHelpers } from "formik"
import * as Yup from "yup"
import { graphql } from "~/__generated__"
import { PrivacyStatusEnum } from "~/__generated__/graphql"
import { useGraphQLQuery, useGraphQLMutation } from "~/graphql/graphql-hook"
import { Fieldset } from "~/components/Fieldset"
import { TextField } from "~/components/FormFields/TextField"
import { RadioButtonGroup } from "~/components/FormFields/RadioButtonGroup"
import { QuestionResponseSuccess } from "~/components/QuestionResponseSuccess"
import { Button } from "~/components/Button"
import { displayErrors } from "~/util/validations"

type QuestionResponseValues = {
  responseText: string
  from: string
  openToOneOnOne: boolean
  privacyStatus: PrivacyStatusEnum
}

const responseLimit = 2000

const validationSchema = Yup.object({
  responseText: Yup.string()
    .max(responseLimit)
    .required("Your response is required"),
  openToOneOnOne: Yup.boolean().required(),
})

export const QuestionResponseRoute = () => {
  const { questionId } = useParams()

  const mutation = useGraphQLMutation(CREATE_RESPONSE_MUTATION)
  const questionResult = useGraphQLQuery(RESPONSE_QUESTION_DOCUMENT, {
    id: questionId!,
  })

  if (questionResult.status === "error") return <div>error</div>
  if (questionResult.status === "loading") return <div>loading …</div>

  const { question } = questionResult.data

  const privacyStatusOptions = [
    {
      label: "My response is private and confidential to the requester",
      value: PrivacyStatusEnum.Private,
    },
    {
      label: "My response can be anonymously shared",
      value: PrivacyStatusEnum.Anonymous,
    },
    {
      label: "My response and identity can be shared",
      value: PrivacyStatusEnum.Public,
    },
  ]

  const initialValues = {
    responseText: "",
    from: "",
    openToOneOnOne: true,
    privacyStatus: PrivacyStatusEnum.Private,
  }

  const onSubmit = (
    values: QuestionResponseValues,
    { setSubmitting }: FormikHelpers<QuestionResponseValues>,
  ) => {
    mutation.mutate(
      {
        input: {
          questionId: questionId!,
          ...values,
        },
      },
      {
        onError: (errors) => displayErrors(errors),
        onSettled: () => setSubmitting(false),
      },
    )
  }

  if (mutation.isSuccess)
    return (
      <QuestionResponseSuccess
        questionId={question.id}
        payoutInDollars={question.costPerResponseInDollars}
      />
    )

  return (
    <div className="max-w-3xl m-auto">
      <h1>Write your response</h1>

      <hr className="my-8" />

      <p>
        <strong>Question:</strong>
        <br />
        <span className="text-xl">{question.question}</span>
        <br />
        <small>From {question.from}</small>
      </p>
      {question.disclaimer && (
        <p className="mt-8">
          <strong>Disclaimer:</strong>
          <br />
          {question.disclaimer}
        </p>
      )}
      {question.costPerResponseInDollars.toString() !== "0" && (
        <p className="mt-8">
          <strong>Bounty:</strong>
          <br />${question.costPerResponseInDollars} for a confirmed authentic
          response
        </p>
      )}

      <hr className="my-8" />

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <Fieldset
              legend={`Your 5 minute response (${responseLimit} characters max)`}
            >
              <TextField as="textarea" name="responseText" rows={8} />
              <TextField
                label="A bit about you"
                name="from"
                labelClassName="mt-4"
              />
            </Fieldset>

            {question.requestOneOnOne && question.public && (
              <Fieldset legend="Optional next steps" className="mt-8">
                {question.requestOneOnOne && (
                  <RadioButtonGroup
                    label={`Open to followup discussion${
                      question.costPerConsultationHourInDollars.toString() !==
                      "0"
                        ? ` at $${
                            question.costPerConsultationHourInDollars
                          } per hour with a $${
                            question.costPerConsultationHourInDollars / 2
                          } minimum?`
                        : ""
                    }`}
                    name="openToOneOnOne"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                  />
                )}
                {question.public && (
                  <RadioButtonGroup
                    label="Privacy of response"
                    name="privacyStatus"
                    options={privacyStatusOptions}
                    layout="vertical"
                    className="mt-4"
                  />
                )}
              </Fieldset>
            )}

            <div className="mt-8">
              <Button
                type="submit"
                disabled={isSubmitting}
                label="Submit response"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export const CREATE_RESPONSE_MUTATION = graphql(`
  mutation QuestionResponseCreate($input: QuestionResponseCreateInput!) {
    questionResponseCreate(input: $input) {
      questionRequest {
        id
      }
    }
  }
`)

export const RESPONSE_QUESTION_DOCUMENT = graphql(`
  query ResponseQuestionDocument($id: ID!) {
    question(id: $id) {
      id
      question
      from
      disclaimer
      public
      requestOneOnOne
      costPerResponseInDollars
      costPerConsultationHourInDollars
    }
  }
`)
