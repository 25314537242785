import { get, camelCase, capitalize } from "lodash"
import { toast } from "react-hot-toast"
import { GraphQLError } from "~/graphql/graphql-hook"

export type SetFieldErrorType = (
  field: string,
  message: string | undefined,
) => void

const attributeDisplayName = (name: string) =>
  capitalize(name.replace("_", " "))

const showFieldErrors = (
  key: string,
  errors: string[],
  setFieldError: SetFieldErrorType,
) => {
  const errorMessages = errors?.map(
    (error) => `${attributeDisplayName(key)} ${error}`,
  )
  if (errorMessages?.length) {
    setFieldError(camelCase(key), `${errorMessages.join(", ")}`)
  }
}

export const displayErrors = (
  responseErrors?: GraphQLError,
  setFieldError?: SetFieldErrorType,
) => {
  const errors = get(responseErrors, "response.errors")

  const validationErrors = errors?.find(
    (error) => error.extensions?.code === "VALIDATION_ERROR",
  )

  if (validationErrors) {
    toast.error(
      validationErrors.message || "An error has occurred, please try again",
    )

    if (setFieldError) {
      const fields = Object.keys(validationErrors.extensions).filter(
        (key) => key !== "code",
      )
      fields.forEach((key) => {
        const errors = validationErrors.extensions[key] as string[]
        showFieldErrors(key, errors, setFieldError)
      })
    }
  } else {
    toast.error(
      (errors?.length && errors[0].message) ||
        "An error has occurred, please try again",
    )
  }
}
