import clsx from "clsx"
import { trim } from "lodash"
import { Button } from "../Button"
import { useGraphQLMutation } from "~/graphql/graphql-hook"
import { graphql } from "~/__generated__"
import { displayErrors } from "~/util/validations"
import { useState } from "react"
import { toast } from "react-hot-toast"

export const AdminAddRespondent = ({
  questionId,
  reloadRespondents,
}: {
  questionId: string
  reloadRespondents: () => void
}) => {
  const [identityEmailOrId, setIdentityEmailOrId] = useState("")
  const addIdentityMutation = useGraphQLMutation(ADMIN_ADD_IDENTITY_DOCUMENT)

  const submit = () => {
    addIdentityMutation.mutate(
      { input: { questionId, identityEmailsOrIds: [identityEmailOrId] } },
      {
        onError: (error) => displayErrors(error),
        onSuccess: (data) => {
          if (data.adminAddIdentitiesToQuestion?.successMessage) {
            setIdentityEmailOrId("")
            reloadRespondents()
            toast.success(data.adminAddIdentitiesToQuestion.successMessage)
          }
        },
      },
    )
  }

  return (
    <div className="flex">
      <input
        className={clsx("dark:bg-black w-full")}
        type="text"
        value={identityEmailOrId}
        onChange={(e) => setIdentityEmailOrId(trim(e.target.value))}
        placeholder="Enter email or ID"
      />
      <Button
        label={addIdentityMutation.isLoading ? " … " : "Add"}
        type="button"
        className="md:w-full md:pr-6 flex-1 py-0 rounded-tl-none rounded-bl-none"
        onClick={submit}
        disabled={
          identityEmailOrId.length === 0 || addIdentityMutation.isLoading
        }
      />
    </div>
  )
}

export const ADMIN_ADD_IDENTITY_DOCUMENT = graphql(`
  mutation AdminAddIdentitiesToQuestion($input: AddIdentitiesToQuestionInput!) {
    adminAddIdentitiesToQuestion(input: $input) {
      successMessage
    }
  }
`)
