import { useState } from "react"
import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { QuestionStateEnum } from "~/__generated__/graphql"
import { useGraphQLMutation } from "~/graphql/graphql-hook"
import { useAdminQuestion } from "~/components/Admin/AdminQuestionContext"
import { AdminQuestionRejectionForm } from "~/components/Admin/AdminQuestionRejectionForm"
import { Button } from "~/components/Button"
import { displayErrors } from "~/util/validations"

export const AdminQuestionModeration = () => {
  const { questionResult } = useAdminQuestion()
  const [renderRejectForm, setRenderRejectForm] = useState(false)

  const openMutation = useGraphQLMutation(OPEN_QUESTION_MUTATION)
  const closeMutation = useGraphQLMutation(CLOSE_QUESTION_MUTATION)

  if (!questionResult.data) return null
  const { question } = questionResult.data

  if (question.state === QuestionStateEnum.AdminRejected)
    return <AdminQuestionRejectionForm />

  const onOpen = () => {
    openMutation.mutate(
      {
        input: {
          id: question.id,
        },
      },
      {
        onError: (error) => displayErrors(error),
        onSuccess: () => {
          toast.success("Question is now open to responses")
          questionResult.refetch()
        },
      },
    )
  }

  const onClose = () => {
    closeMutation.mutate(
      {
        input: {
          id: question.id,
        },
      },
      {
        onError: (error) => displayErrors(error),
        onSuccess: () => {
          toast.success("Question is now closed to responses")
          questionResult.refetch()
        },
      },
    )
  }

  return (
    <>
      <h2 className="mb-4">Moderation</h2>
      {question.state === QuestionStateEnum.Submitted && (
        <>
          {!renderRejectForm ? (
            <div className="flex gap-4">
              <Button label="Approve" onClick={onOpen} />
              <Button
                label="Reject"
                onClick={() => setRenderRejectForm((prev) => !prev)}
                className="bg-red-700"
              />
            </div>
          ) : (
            <AdminQuestionRejectionForm
              onCancel={() => setRenderRejectForm(false)}
            />
          )}
        </>
      )}
      {question.state === QuestionStateEnum.Open && (
        <Button label="Close" onClick={onClose} />
      )}
      {question.state === QuestionStateEnum.Closed && (
        <Button label="Reopen to responses" onClick={onOpen} />
      )}
    </>
  )
}

const OPEN_QUESTION_MUTATION = graphql(`
  mutation OpenQuestion($input: OpenQuestionInput!) {
    openQuestion(input: $input) {
      question {
        id
        state
      }
    }
  }
`)

const CLOSE_QUESTION_MUTATION = graphql(`
  mutation CloseQuestion($input: CloseQuestionInput!) {
    closeQuestion(input: $input) {
      question {
        id
        state
      }
    }
  }
`)
