import { useState } from "react"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import * as Yup from "yup"
import clsx from "clsx"
import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { UserStatusEnum } from "~/__generated__/graphql"
import { useGraphQLMutation, useGraphQLQuery } from "~/graphql/graphql-hook"
import { Fieldset } from "~/components/Fieldset"
import { TextField } from "~/components/FormFields/TextField"
import { RadioButtonGroup } from "~/components/FormFields/RadioButtonGroup"
import { FieldWithDescription } from "~/components/FieldWithDescription"
import { Button } from "~/components/Button"
import { assert } from "~/util/assert"
import { displayErrors } from "~/util/validations"
import { pickSuggestionsPath } from "~/util/paths"

type QuestionValues = {
  question: string
  from: string
  durationInHours: number
  numberOfResponses: number
  requestOneOnOne: boolean
  public: boolean
  targetingPositive: string
  targetingNegative: string
  disclaimer: string
  includeEmails: string
  excludeEmails: string
}

export const CREATE_QUESTION_MUTATION = graphql(`
  mutation QuestionCreate($input: QuestionCreateInput!) {
    questionCreate(input: $input) {
      question {
        id
      }
    }
  }
`)

const CURRENT_USER_QUERY_DOCUMENT = graphql(`
  query QuestionCreateCurrentUser {
    currentUser {
      id
      name
      email
      jobTitle
      companyName
      costPerResponseInDollars
      costPerConsultationHourInDollars
      minResponses
      maxResponses
      maxQuestionDurationInHours
      allowOneOnOneRequests
      allowPrivateQuestions
      status
    }
  }
`)

export const NewQuestionRoute = () => {
  const [showAdvancedFields, setShowAdvancedFields] = useState(false)

  const userQuery = useGraphQLQuery(CURRENT_USER_QUERY_DOCUMENT)
  const mutation = useGraphQLMutation(CREATE_QUESTION_MUTATION)

  if (userQuery.status === "error") return <div>error</div>
  if (userQuery.status === "loading") return <div>loading …</div>

  const { currentUser } = userQuery.data
  assert(currentUser)

  if (currentUser.status !== UserStatusEnum.Active)
    return (
      <div className="text-2xl text-center">
        Your account must be approved before you can ask a question.
      </div>
    )

  const validationSchema = Yup.object({
    question: Yup.string().required("Your question is required"),
    from: Yup.string().required("Who is a required field"),
    targetingPositive: Yup.string().required(
      "Please describe who you want to ask.",
    ),
    durationInHours: Yup.number()
      .min(24)
      .max(currentUser.maxQuestionDurationInHours)
      .required()
      .label("Duration in hours"),
    numberOfResponses: Yup.number()
      .min(currentUser.minResponses)
      .max(currentUser.maxResponses)
      .required()
      .label("Number of responses"),
    requestOneOnOne: Yup.boolean().required(),
    public: Yup.boolean().required(),
  })

  const title =
    currentUser.jobTitle || currentUser.companyName
      ? `(${currentUser.jobTitle || ""}${
          (currentUser.jobTitle && currentUser.companyName && ", ") || ""
        }${currentUser.companyName || ""})`
      : ""

  const initialValues = {
    question: "",
    from: currentUser.name ? `${currentUser.name} ${title}` : "Anonymous",
    durationInHours: 24,
    numberOfResponses: currentUser.minResponses,
    requestOneOnOne: currentUser.allowOneOnOneRequests,
    public: currentUser.allowPrivateQuestions,
    targetingPositive: "",
    targetingNegative: "",
    disclaimer: "",
    includeEmails: "",
    excludeEmails: "",
  }

  const yesNoOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]

  const onSubmit = (
    values: QuestionValues,
    { setSubmitting, setFieldError }: FormikHelpers<QuestionValues>,
  ) => {
    mutation.mutate(
      {
        input: values,
      },
      {
        onError: (error) => displayErrors(error, setFieldError),
        onSuccess: (data) => {
          toast.success("Question submitted")
          if (data.questionCreate?.question)
            window.location.pathname = pickSuggestionsPath({
              questionId: data.questionCreate.question.id,
            })
        },
        onSettled: () => setSubmitting(false),
      },
    )
  }

  return (
    <div className="max-w-7xl mx-auto">
      <p className="text-2xl mb-8">
        Get fast, specific, accurate answers from true experts in tech, media,
        and finance you can't reach elsewhere.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values }: FormikProps<QuestionValues>) => (
          <Form>
            <Fieldset legend="Your Question">
              <FieldWithDescription
                description="The question you want to ask. Should be something an expert can answer in less than 5 minutes in 2,000 characters max."
                hasLabel={false}
              >
                <TextField as="textarea" name="question" rows={4} />
              </FieldWithDescription>

              <FieldWithDescription
                description="How you want yourself described to people being asked."
                className="mt-4"
              >
                <TextField label="Who is asking?" name="from" />
              </FieldWithDescription>
            </Fieldset>

            <Fieldset legend="Targeting" className="mt-8">
              <FieldWithDescription description="Who you would most like to hear answers from/is likely to have good answers">
                <TextField
                  label="Describe who do you want to ask?"
                  name="targetingPositive"
                  as="textarea"
                  rows={4}
                />
              </FieldWithDescription>

              <FieldWithDescription
                description="Who you would like to avoid asking (i.e. current employees of a specific company in some situations)"
                className="mt-4"
              >
                <TextField
                  label="Describe who do you not want to ask?"
                  name="targetingNegative"
                  as="textarea"
                  rows={4}
                />
              </FieldWithDescription>

              <FieldWithDescription
                description="Details that will be included in the email request directly/if you have specific requests"
                className="mt-4"
              >
                <TextField
                  label="Anything specific you need respondants to know?"
                  name="disclaimer"
                  as="textarea"
                  rows={4}
                />
              </FieldWithDescription>
            </Fieldset>

            <div className="mt-8 flex items-baseline">
              <h2>Advanced Settings</h2>
              <button
                type="button"
                onClick={() => setShowAdvancedFields((prev) => !prev)}
                className="pl-4 inline-block underline hover:text-pink-500"
              >
                [{showAdvancedFields ? "hide" : "show"}]
              </button>
            </div>
            <Fieldset
              legend="Request Details"
              className={clsx("mt-4", showAdvancedFields ? "block" : "hidden")}
            >
              <FieldWithDescription
                description={`${
                  currentUser.costPerResponseInDollars !== 0
                    ? `Responses cost $${currentUser.costPerResponseInDollars} each. `
                    : ""
                }Stop after how many responses?`}
              >
                <TextField
                  label="Number of requested responses"
                  name="numberOfResponses"
                  type="number"
                />
                <small>
                  Value must be between {currentUser.minResponses} and{" "}
                  {currentUser.maxResponses}
                </small>
              </FieldWithDescription>

              <FieldWithDescription
                description="Time the question will stay active, in hours"
                className="mt-4"
              >
                <TextField
                  label="Leave question open for …"
                  name="durationInHours"
                  type="number"
                />
                <small>
                  Value must be between 24 and{" "}
                  {currentUser.maxQuestionDurationInHours}
                </small>
              </FieldWithDescription>

              <FieldWithDescription
                description={`Ask if responders are interested in talking live${
                  currentUser.costPerConsultationHourInDollars !== 0
                    ? ` at $${currentUser.costPerConsultationHourInDollars}/hr with a half hour minimum.`
                    : "."
                }`}
                className={clsx(
                  "mt-4",
                  !currentUser.allowOneOnOneRequests && "opacity-50",
                )}
              >
                <RadioButtonGroup
                  label="Request possible 1:1 followup consultations?"
                  name="requestOneOnOne"
                  options={yesNoOptions}
                  disabled={!currentUser.allowOneOnOneRequests}
                />
              </FieldWithDescription>

              <FieldWithDescription
                description="Allow the public to see the question and responses."
                className={clsx(
                  "mt-4",
                  !currentUser.allowPrivateQuestions && "opacity-50",
                )}
              >
                <RadioButtonGroup
                  label="Allow question to be “public” after one week"
                  name="public"
                  options={yesNoOptions}
                  disabled={!currentUser.allowPrivateQuestions}
                />
              </FieldWithDescription>

              <FieldWithDescription
                description="Add optional email addresses, separated by commas"
                className="mt-4"
              >
                <TextField
                  label="Any specific people you want to ask?"
                  name="includeEmails"
                  placeholder="user1@example.com, user2@example.com, …"
                />
              </FieldWithDescription>

              <FieldWithDescription
                description="Add optional email addresses, separated by commas"
                className="mt-4"
              >
                <TextField
                  label="Any specific people who you do not want to ask?"
                  name="excludeEmails"
                  placeholder="user1@example.com, user2@example.com, …"
                />
              </FieldWithDescription>
            </Fieldset>

            <div className="mt-8">
              <Button type="submit" disabled={isSubmitting} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
