import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { RouterProvider } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import colors from "tailwindcss/colors"
import { router } from "~/routes/router"
import "tippy.js/dist/tippy.css"
import { CurrentUserProvider } from "./components/ViewerContext"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CurrentUserProvider>
        <Toaster
          toastOptions={{
            success: {
              style: {
                color: colors.white,
                backgroundColor: colors.pink[500],
              },
            },
            error: {
              style: {
                color: colors.white,
                backgroundColor: colors.red[700],
              },
            },
          }}
        />
        <RouterProvider router={router} />
      </CurrentUserProvider>
    </QueryClientProvider>
  )
}
