import { QuestionStateEnum } from "~/__generated__/graphql"
import { useAdminQuestion } from "~/components/Admin/AdminQuestionContext"
import { AdminQuestionRequestEmailPreview } from "~/components/Admin/AdminQuestionRequestEmailPreview"
import { AdminQuestionModeration } from "~/components/Admin/AdminQuestionModeration"
import { MultilineText } from "~/components/MultilineText"

export const AdminQuestionOverviewRoute = () => {
  const { questionResult } = useAdminQuestion()

  if (!questionResult.data) return null
  const { question } = questionResult.data

  return (
    <div>
      <div className="flex flex-wrap gap-x-8">
        <div>
          <strong>Status:</strong> {question.state}
        </div>
        <div>
          <strong>One on One:</strong> {question.requestOneOnOne ? "Yes" : "No"}
        </div>
        <div>
          <strong>Public:</strong> {question.requestOneOnOne ? "Yes" : "No"}
        </div>
        <div>
          <strong># of Responses:</strong> {question.numberOfResponses}
        </div>
        <div>
          <strong>Open for:</strong> {question.durationInHours} hours
        </div>
      </div>

      <hr className="my-8" />

      <div className="max-w-3xl">
        <h2 className="mb-4">Full question</h2>
        <MultilineText text={question.question} />

        <hr className="my-8" />
        <AdminQuestionModeration />

        {question.state !== QuestionStateEnum.AdminRejected && (
          <>
            <hr className="my-8" />
            <AdminQuestionRequestEmailPreview />
          </>
        )}
      </div>
    </div>
  )
}
