import { Field, useField } from "formik"
import clsx from "clsx"
import TextareaAutosize from "react-textarea-autosize"
import { FieldLabel } from "~/components/FieldLabel"
import { FieldError } from "~/components/FieldError"

interface TextFieldProps {
  as?: string
  autoComplete?: string
  className?: string
  disabled?: boolean
  hidden?: boolean
  label?: string
  name: string
  placeholder?: string
  rows?: number
  type?: string
  min?: number
  max?: number
  labelClassName?: string
}

export const TextField = (props: TextFieldProps) => {
  const { as, label, name, className, labelClassName, rows, ...inputProps } =
    props
  const [field, meta] = useField(name)

  const fieldOutput = (
    <>
      {as === "textarea" ? (
        <TextareaAutosize
          id={name}
          className={clsx(
            "w-full dark:bg-black",
            meta.touched && meta.error && "border-red-700",
            className,
          )}
          minRows={rows}
          {...inputProps}
          {...field}
        />
      ) : (
        <Field
          id={name}
          className={clsx(
            "w-full dark:bg-black",
            meta.touched && meta.error && "border-red-700",
            className,
          )}
          type="text"
          {...inputProps}
          {...field}
        />
      )}
      {meta.touched && meta.error && <FieldError message={meta.error} />}
    </>
  )

  return label ? (
    <FieldLabel label={label} className={labelClassName}>
      {fieldOutput}
    </FieldLabel>
  ) : (
    fieldOutput
  )
}
