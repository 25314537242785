import { Formik, Form } from "formik"
import * as Yup from "yup"
import { graphql } from "~/__generated__"
import { useGraphQLMutation } from "~/graphql/graphql-hook"
import { TextField } from "~/components/FormFields/TextField"
import { Button } from "~/components/Button"

interface QuestionResponseSuccessProps {
  questionId: string
  payoutInDollars: number
}

type QuestionResponseAddUserDetailsValues = {
  questionId: string
  emails?: string
  userProvidedDetails?: string
}

export const QuestionResponseSuccess = ({
  questionId,
  payoutInDollars,
}: QuestionResponseSuccessProps) => {
  const mutation = useGraphQLMutation(ADD_USER_DETAILS_MUTATION)

  const validationSchema = Yup.object({
    emails: Yup.string().email(),
    userProvidedDetails: Yup.string().max(2000),
  })

  const onSubmit = (values: QuestionResponseAddUserDetailsValues) => {
    mutation.mutate({ input: values })
  }

  return (
    <div className="max-w-3xl m-auto">
      <div className="text-center">
        <h1>Thank you!</h1>
        <p className="mt-4 max-w-xl m-auto">
          Your response has been submitted and ${payoutInDollars} will be
          credited to your account once it has been reviewed and accepted.
        </p>
      </div>
      <hr className="hr my-8" />
      {mutation.isSuccess ? (
        <p className="text-center">Additional details submitted.</p>
      ) : (
        <Formik
          initialValues={{
            questionId,
            emails: "",
            userProvidedDetails: "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <TextField
                name="email"
                label="Refer anyone who might have a great anwer to this question"
                placeholder="user1@example.com, user2@example.com, …"
              />
              <TextField
                as="textarea"
                name="userProvidedDetails"
                label="So we can deliver more relevant questions, tell us a bit more about yourself"
                rows={6}
                labelClassName="mt-8"
              />

              <div className="mt-8">
                <Button type="submit" disabled={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

const ADD_USER_DETAILS_MUTATION = graphql(`
  mutation QuestionResponseAddUserDetails(
    $input: QuestionResponseAddUserDetailsInput!
  ) {
    questionResponseAddUserDetails(input: $input) {
      clientMutationId
    }
  }
`)
