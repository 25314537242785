import clsx from "clsx"

interface FancyRadioButtonProps {
  label: string
  name: string
  className?: string
  checkedColor?: "red" | "green"
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FancyRadioButton = ({
  label,
  name,
  className,
  onChange,
  checkedColor,
}: FancyRadioButtonProps) => {
  let activeColors

  switch (checkedColor) {
    case "red":
      activeColors = "peer-checked:bg-red-700 peer-checked:border-red-700"
      break
    case "green":
      activeColors = "peer-checked:bg-green-700 peer-checked:border-green-700"
      break
    default:
      activeColors = "peer-checked:bg-pink-500 peer-checked:border-pink-500"
  }

  return (
    <label className={className}>
      <input
        type="radio"
        name={name}
        className="peer hidden"
        onChange={onChange}
      />
      <span
        className={clsx(
          "border-2 p-1 px-2 peer-checked:text-white dark:border-slate-500",
          activeColors,
        )}
      >
        {label}
      </span>
    </label>
  )
}
