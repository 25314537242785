import { useParams } from "react-router-dom"
import { FormikHelpers } from "formik"
import { capitalize } from "lodash"
import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { useGraphQLQuery, useGraphQLMutation } from "~/graphql/graphql-hook"
import { adminUsersPath } from "~/util/paths"
import { displayErrors } from "~/util/validations"
import { parseUserStatus } from "~/util/parseEnums"
import { AdminUserForm, UserValues } from "~/components/Admin/AdminUserForm"
import { Breadcrumb } from "~/components/Breadcrumb"

export const AdminUserUpdateRoute = () => {
  const { userId } = useParams()
  const userQuery = useGraphQLQuery(ADMIN_USER_ROUTE_DOCUMENT, {
    userId: userId!,
  })
  const userMutation = useGraphQLMutation(UPDATE_USER_MUTATION)

  if (userQuery.status === "error") return <div>error</div>
  if (userQuery.status === "loading") return <div>loading …</div>

  const { adminUser: user } = userQuery.data

  const initialValues: UserValues = {
    email: user.email,
    name: user.name ?? "",
    companyName: user.companyName ?? "",
    jobTitle: user.jobTitle ?? "",
    bio: user.bio ?? "",
    isAdmin: user.isAdmin,
    costPerResponseInDollars: user.costPerResponseInDollars,
    costPerConsultationHourInDollars: user.costPerConsultationHourInDollars,
    minResponses: user.minResponses,
    maxResponses: user.maxResponses,
    maxQuestionDurationInHours: user.maxQuestionDurationInHours,
    allowOneOnOneRequests: user.allowOneOnOneRequests,
    allowPrivateQuestions: user.allowPrivateQuestions,
    canAskQuestions: user.canAskQuestions,
  }

  const onSubmit = (
    values: UserValues,
    { setSubmitting, setFieldError }: FormikHelpers<UserValues>,
  ) => {
    userMutation.mutate(
      {
        input: {
          id: userId!,
          userInput: values,
        },
      },
      {
        onSuccess: () => {
          toast.success("User updated")
          userQuery.refetch()
        },
        onError: (error) => {
          displayErrors(error, setFieldError)
        },
        onSettled: () => {
          setSubmitting(false)
        },
      },
    )
  }

  return (
    <div className="max-w-3xl m-auto">
      <Breadcrumb path={adminUsersPath} label="Users" className="mb-8" />
      {userQuery.isSuccess && (
        <>
          <div className="mb-8">
            <h1>Editing {user.name}</h1>
            <small>
              {capitalize(parseUserStatus(user.status))} • {user.id}
            </small>
          </div>
          <AdminUserForm initialValues={initialValues} onSubmit={onSubmit} />
        </>
      )}
    </div>
  )
}

export const ADMIN_USER_ROUTE_DOCUMENT = graphql(`
  query AdminUserRoute($userId: ID!) {
    adminUser(userId: $userId) {
      id
      email
      name
      name
      companyName
      jobTitle
      bio
      isAdmin
      costPerResponseInDollars
      costPerConsultationHourInDollars
      minResponses
      maxResponses
      maxQuestionDurationInHours
      allowOneOnOneRequests
      allowPrivateQuestions
      canAskQuestions
      status
    }
  }
`)

const UPDATE_USER_MUTATION = graphql(`
  mutation UpdateUserMutation($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        id
        email
        name
        companyName
        jobTitle
        bio
        isAdmin
        costPerResponseInDollars
        costPerConsultationHourInDollars
        minResponses
        maxResponses
        maxQuestionDurationInHours
        allowOneOnOneRequests
        allowPrivateQuestions
        canAskQuestions
      }
    }
  }
`)
