import { NavLink, Outlet } from "react-router-dom"
import { graphql } from "~/__generated__"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import {
  adminQuestionsPath,
  adminFollowupRequestsPath,
  adminUsersPath,
  adminResponsesPath,
} from "~/util/paths"

const navLinkClassnames = ({ isActive }: { isActive: boolean }) =>
  isActive ? "text-black no-underline dark:text-white" : ""

export const AdminLayout = () => {
  const result = useGraphQLQuery(ADMIN_LAYOUT_QUERY_DOCUMENT)

  const responsesCount = result?.data?.adminResponses.totalCount
  const followupsCount = result?.data?.adminFollowupRequests.totalCount

  return (
    <div>
      <div className="pb-8 border-b mb-8">
        <NavLink to={adminQuestionsPath} className={navLinkClassnames}>
          Questions
        </NavLink>{" "}
        |{" "}
        <NavLink to={adminResponsesPath} className={navLinkClassnames}>
          Responses {responsesCount != null ? `(${responsesCount})` : ""}
        </NavLink>{" "}
        |{" "}
        <NavLink to={adminFollowupRequestsPath} className={navLinkClassnames}>
          Followups {followupsCount != null ? `(${followupsCount})` : ""}
        </NavLink>{" "}
        |{" "}
        <NavLink to={adminUsersPath} className={navLinkClassnames}>
          Users
        </NavLink>{" "}
        |{" "}
        <a href="/admin/sidekiq" className="">
          Sidekiq
        </a>
      </div>

      <Outlet />
    </div>
  )
}

const ADMIN_LAYOUT_QUERY_DOCUMENT = graphql(`
  query AdminLayout {
    adminResponses {
      totalCount
    }
    adminFollowupRequests {
      totalCount
    }
  }
`)
