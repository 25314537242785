import { Fragment, useCallback, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Tippy from "@tippyjs/react"
import { graphql } from "~/__generated__"
import {
  QuestionRequestSourceEnum,
  QuestionRequestStateEnum,
} from "~/__generated__/graphql"
import { AdminAddRespondent } from "~/components/Admin/AdminAddRespondent"
import { AdminAddRespondentsCsv } from "~/components/Admin/AdminAddRespondentsCsv"
import { TableCell } from "~/components/Admin/TableCell"
import { Button } from "~/components/Button"
import { useGraphQLMutation, useGraphQLQuery } from "~/graphql/graphql-hook"
import { assert } from "~/util/assert"
import {
  adminQuestionRequestPath,
  newsletterCmsDocumentUrl,
  newsletterCmsIdentityUrl,
  respondentsCsvPath,
} from "~/util/paths"
import { displayErrors } from "~/util/validations"
import toast from "react-hot-toast"

export const AdminQuestionRespondentsRoute = () => {
  const { questionId } = useParams<{ questionId: string }>()
  assert(questionId)

  const [idsToSubmit, setIdsToSubmit] = useState<Array<string>>([])

  const queryResult = useGraphQLQuery(ADMIN_QUESTIONS_QUERY_DOCUMENT, {
    questionId,
  })
  const sendQuestionMutation = useGraphQLMutation(
    ADMIN_SEND_QUESTION_TO_RESPONDENTS_DOCUMENT,
  )
  const rejectSuggestionMutation = useGraphQLMutation(
    ADMIN_REJECT_SUGGESTIONS_MUTATION,
  )
  const rerunAiScreeningMutation = useGraphQLMutation(ADMIN_RERUN_AI_SCREENING)

  const toggleCheck = useCallback(
    (id: string) => {
      const index = idsToSubmit.indexOf(id)

      if (index === -1) {
        setIdsToSubmit([...idsToSubmit, id])
      } else {
        setIdsToSubmit([
          ...idsToSubmit.slice(0, index),
          ...idsToSubmit.slice(index + 1),
        ])
      }
    },
    [idsToSubmit, setIdsToSubmit],
  )

  const sendQuestion = (requestIds: string[]) => {
    sendQuestionMutation.mutate(
      { input: { requestIds } },
      {
        onError: (error) => displayErrors(error),
        onSuccess: () => {
          setIdsToSubmit([])
          queryResult.refetch()
        },
      },
    )
  }

  const rejectSuggestions = (requestIds: string[]) => {
    rejectSuggestionMutation.mutate(
      { input: { requestIds: requestIds } },
      {
        onError: (error) => displayErrors(error),
        onSuccess: () => {
          setIdsToSubmit([])
          queryResult.refetch()
        },
      },
    )
  }

  const rerunAiScreening = () => {
    rerunAiScreeningMutation.mutate(
      { input: { questionId } },
      {
        onError: (error) => displayErrors(error),
        onSuccess: () => {
          toast.success(
            "Rerunning screening.  Please refresh after a few seconds.",
          )
          queryResult.refetch()
        },
      },
    )
  }

  if (queryResult.status === "error") return <div>error</div>
  if (queryResult.status === "loading") return <div>loading …</div>

  const allRespondentsSelected =
    idsToSubmit.length ===
    queryResult.data.adminRequestsForQuestion.nodes.length

  const toggleCheckAll = () => {
    setIdsToSubmit(
      allRespondentsSelected
        ? []
        : queryResult.data.adminRequestsForQuestion.nodes.map((n) => n.id),
    )
  }

  return (
    <div>
      <div className="md:flex flex-wrap mb-8 gap-6">
        <div>
          <AdminAddRespondentsCsv
            questionId={questionId}
            reloadRespondents={() => queryResult.refetch()}
          />
          <div className="py-2">– or –</div>
          <AdminAddRespondent
            questionId={questionId}
            reloadRespondents={() => queryResult.refetch()}
          />
        </div>
        <div className="mt-4 md:mt-0 md:min-w-xl flex-1 md:border-s md:border-e md:ps-6">
          <div className="font-bold">Targeting Positive:</div>
          <div className="mb-4">
            {queryResult.data.question.targetingPositive}
          </div>
          <div className="font-bold">Enhanced Query:</div>
          <div className="mb-4">{queryResult.data.question.enhancedQuery}</div>
          <div className="font-bold">Targeting Negative:</div>
          <div>{queryResult.data.question.targetingNegative || "n/a"}</div>
        </div>
        <div className="mt-4 md:mt-0 md:max-w-xs">
          <a
            href={respondentsCsvPath({ id: questionId })}
            className="btn btn-primary w-full"
            target="_blank"
            rel="noreferrer"
          >
            Download CSV
          </a>
          <br />
          <Button
            className="mt-2 md:w-full bg-green-700"
            label="Send emails"
            type="button"
            disabled={
              sendQuestionMutation.isLoading || idsToSubmit.length === 0
            }
            onClick={() => sendQuestion(idsToSubmit)}
          />
          <Button
            className="mt-2 md:w-full bg-red-700"
            label="Reject suggestions"
            type="button"
            disabled={
              rejectSuggestionMutation.isLoading || idsToSubmit.length === 0
            }
            onClick={() => rejectSuggestions(idsToSubmit)}
          />
          <Button
            className="mt-2 md:w-full btn-primary"
            label="Re-run AI Screening"
            type="button"
            disabled={rerunAiScreeningMutation.isLoading}
            onClick={() => rerunAiScreening()}
          />
        </div>
      </div>
      <div className="overflow-x-auto mt-8 text-sm">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <TableCell heading>
                <input
                  type="checkbox"
                  checked={allRespondentsSelected}
                  onChange={toggleCheckAll}
                />
              </TableCell>
              <TableCell heading>Rel</TableCell>
              <TableCell heading>Name</TableCell>
              <TableCell heading>Email</TableCell>
              <TableCell heading>Screening</TableCell>
              <TableCell heading>Status</TableCell>
              <TableCell heading>Sources</TableCell>
              <TableCell heading>Links</TableCell>
              <TableCell heading>Actions</TableCell>
            </tr>
          </thead>
          <tbody>
            {queryResult.data.adminRequestsForQuestion.nodes.map((request) => (
              <tr key={request.id}>
                <TableCell>
                  {request.state === QuestionRequestStateEnum.Suggested && (
                    <input
                      type="checkbox"
                      checked={idsToSubmit.includes(request.id)}
                      onChange={() => toggleCheck(request.id)}
                    />
                  )}
                </TableCell>
                <TableCell>{request.relevancyScore.toFixed(3)}</TableCell>
                <TableCell>{request.identityProfile.name}</TableCell>
                <TableCell>{request.identityProfile.email}</TableCell>
                <TableCell>{request.aiScreeningReasoning}</TableCell>
                <TableCell>{request.state}</TableCell>
                <TableCell>
                  <SourcesContent
                    sources={request.sources}
                    aiScreeningReasoning={request.aiScreeningReasoning || ""}
                  />
                </TableCell>
                <TableCell>
                  <div>
                    <Link
                      to={adminQuestionRequestPath({
                        questionId,
                        requestId: request.id,
                      })}
                    >
                      Details
                    </Link>
                  </div>
                  <div>
                    <a
                      href={newsletterCmsIdentityUrl(
                        request.identityProfile.externalId,
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on CRM
                    </a>
                  </div>
                  <div>
                    <a
                      href={newsletterCmsDocumentUrl(
                        request.identityProfile.externalId,
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Document
                    </a>
                  </div>
                </TableCell>
                <TableCell>
                  {request.state === QuestionRequestStateEnum.Suggested && (
                    <>
                      <button
                        type="button"
                        className="underline hover:text-pink-500"
                        onClick={() => sendQuestion([request.id])}
                      >
                        send
                      </button>{" "}
                      |{" "}
                      <button
                        type="button"
                        className="underline hover:text-pink-500"
                        onClick={() => rejectSuggestions([request.id])}
                      >
                        reject
                      </button>
                    </>
                  )}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const SourcesContent = ({
  sources,
  aiScreeningReasoning,
}: {
  sources: QuestionRequestSourceEnum[]
  aiScreeningReasoning: string
}) => {
  return sources.map((source: QuestionRequestSourceEnum, i: number) => {
    let content = <span>{source}</span>

    if (source === QuestionRequestSourceEnum.AiScreenApproved) {
      content = <span className="text-green-700">{source}</span>
    } else if (source === QuestionRequestSourceEnum.AiScreenRejected) {
      content = <span className="text-red-700">{source}</span>
    } else if (source === QuestionRequestSourceEnum.RequesterApproved) {
      content = (
        <Tippy content="The requester chose this responder">
          <span className="underline text-green-700">{source}</span>
        </Tippy>
      )
    }

    return (
      <Fragment key={source}>
        {content}
        {i < sources.length - 1 ? ", " : ""}
      </Fragment>
    )
  })
}

const ADMIN_SEND_QUESTION_TO_RESPONDENTS_DOCUMENT = graphql(`
  mutation AdminSendQuestionsToRespondents(
    $input: AdminSendQuestionToRespondentsInput!
  ) {
    adminSendQuestionToRespondents(input: $input) {
      question {
        id
      }
    }
  }
`)

const ADMIN_REJECT_SUGGESTIONS_MUTATION = graphql(`
  mutation RejectQuestionRequests($input: RejectQuestionRequestsInput!) {
    rejectQuestionRequests(input: $input) {
      clientMutationId
    }
  }
`)

const ADMIN_RERUN_AI_SCREENING = graphql(`
  mutation AdminRerunAiScreening($input: RerunAiScreeningInput!) {
    adminRerunAiScreening(input: $input) {
      question {
        id
      }
    }
  }
`)

const ADMIN_QUESTIONS_QUERY_DOCUMENT = graphql(`
  query AdminQuestionRespondents($questionId: ID!) {
    question(id: $questionId) {
      id
      targetingPositive
      targetingNegative
      enhancedQuery
    }

    adminRequestsForQuestion(questionId: $questionId) {
      nodes {
        id
        relevancyScore
        state
        sources
        aiScreeningReasoning

        identityProfile {
          id
          externalId
          email
          name
          bio
          bioLong
        }
      }
    }
  }
`)
