import { Formik, Form, FormikHelpers } from "formik"
import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { QuestionStateEnum } from "~/__generated__/graphql"
import { useGraphQLMutation } from "~/graphql/graphql-hook"
import { displayErrors } from "~/util/validations"
import { Button } from "~/components/Button"
import { Fieldset } from "~/components/Fieldset"
import { TextField } from "~/components/FormFields/TextField"
import { useAdminQuestion } from "~/components/Admin/AdminQuestionContext"

interface QuestionRejectionValues {
  rejectionNotes: string
}

const QUESTION_REJECTION_MUTATION = graphql(`
  mutation RejectQuestion($input: RejectQuestionInput!) {
    rejectQuestion(input: $input) {
      question {
        id
      }
    }
  }
`)

export const AdminQuestionRejectionForm = ({
  onCancel,
}: {
  onCancel?: () => void
}) => {
  const { questionResult } = useAdminQuestion()
  const mutation = useGraphQLMutation(QUESTION_REJECTION_MUTATION)

  if (!questionResult.data) return null
  const { question } = questionResult.data

  const onSubmit = (
    values: QuestionRejectionValues,
    { setSubmitting }: FormikHelpers<QuestionRejectionValues>,
  ) => {
    console.log(values)
    mutation.mutate(
      {
        input: {
          id: question.id,
          ...values,
        },
      },
      {
        onError: (error) => displayErrors(error),
        onSuccess: () => {
          toast.success("Question rejected")
          questionResult.refetch()
        },
        onSettled: () => setSubmitting(false),
      },
    )
  }

  if (question.state === QuestionStateEnum.AdminRejected)
    return (
      <div>
        <h2>Rejection notes</h2>
        <p className="my-4">{question.rejectionNotes}</p>
        <small>Message sent at {question.rejectionSentAt}</small>
      </div>
    )

  return (
    <Formik initialValues={{ rejectionNotes: "" }} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <Fieldset legend="Reject">
            <TextField
              name="rejectionNotes"
              as="textarea"
              rows={6}
              label="Note to user"
            />
          </Fieldset>
          <div className="mt-4 flex gap-4">
            <Button
              disabled={isSubmitting}
              label="Reject & send message"
              className="bg-red-700"
              type="submit"
            />
            {onCancel && <Button onClick={onCancel} label="Cancel" />}
          </div>
        </Form>
      )}
    </Formik>
  )
}
