import { NavLink, useMatch } from "react-router-dom"
import clsx from "clsx"
import { FragmentType, graphql, useFragment } from "~/__generated__"
import { Question_AdminHeaderFragmentDoc } from "~/__generated__/graphql"
import {
  adminQuestionPath,
  adminQuestionRequestPath,
  adminQuestionRespondentsPath,
  adminQuestionFollowupRequestsPath,
  adminUserUpdatePath,
} from "~/util/paths"
import { truncatedQuestion } from "~/util/questions"
import { Breadcrumb } from "../Breadcrumb"

const navLinkClassnames = ({ isActive }: { isActive: boolean }) =>
  clsx(
    "inline-block py-2 px-4 border-b-4 no-underline mb-[-1px] whitespace-nowrap",
    isActive
      ? "text-black dark:text-white border-pink-500"
      : "text-gray-400 border-transparent",
  )

export const AdminQuestionHeader = ({
  questionQuery,
}: {
  questionQuery: FragmentType<typeof Question_AdminHeaderFragmentDoc>
}) => {
  const question = useFragment(Question_AdminHeaderFragmentDoc, questionQuery)
  const onRequestPage = !!useMatch(adminQuestionRequestPath.pattern)

  return (
    <div>
      <h1>{truncatedQuestion(question.question)}</h1>
      <p>
        Posted by{" "}
        <NavLink to={adminUserUpdatePath({ userId: question.user.id })}>
          {question.user.name}
        </NavLink>{" "}
        at {question.createdAt}
      </p>

      {onRequestPage ? (
        <div className="my-8">
          <Breadcrumb
            path={adminQuestionRespondentsPath({ questionId: question.id })}
            label="Respondents"
          />
        </div>
      ) : (
        <div className="my-8 mx-[-1rem] px-4 md:mx-0 md:px-0 overflow-hidden overflow-x-auto">
          <div className="border-b flex">
            <NavLink
              to={adminQuestionPath({ questionId: question.id })}
              end
              className={navLinkClassnames}
            >
              Details <span className="hidden sm:inline">& Moderation</span>
            </NavLink>
            <NavLink
              to={adminQuestionRespondentsPath({ questionId: question.id })}
              className={navLinkClassnames}
            >
              Respondents
            </NavLink>
            <NavLink
              to={adminQuestionFollowupRequestsPath({
                questionId: question.id,
              })}
              className={navLinkClassnames}
            >
              Followup Requests
            </NavLink>
          </div>
        </div>
      )}
    </div>
  )
}

graphql(`
  fragment Question_AdminHeader on Question {
    id
    question
    user {
      id
      name
    }
    createdAt
  }
`)
