import { Formik, Form, FormikHelpers } from "formik"
import * as Yup from "yup"
import { Button } from "~/components/Button"
import { Fieldset } from "~/components/Fieldset"
import { TextField } from "~/components/FormFields/TextField"
import { CheckBoxField } from "~/components/FormFields/CheckBoxField"

export type UserValues = {
  email: string
  name: string
  companyName?: string
  jobTitle?: string
  bio?: string
  isAdmin?: boolean
  costPerResponseInDollars?: number
  costPerConsultationHourInDollars?: number
  minResponses?: number
  maxResponses?: number
  maxQuestionDurationInHours?: number
  allowOneOnOneRequests?: boolean
  allowPrivateQuestions?: boolean
  canAskQuestions: boolean
}

interface AdminUserFormProps {
  initialValues: UserValues
  onSubmit: (values: UserValues, helpers: FormikHelpers<UserValues>) => void
}

const validationSchema = Yup.object({
  email: Yup.string().email().required("Email is required"),
  name: Yup.string().required("Name is required"),
  isAdmin: Yup.boolean(),
  costPerResponseInDollars: Yup.number().required().label("Cost per response"),
  costPerConsultationHourInDollars: Yup.number()
    .required()
    .label("Cost per consultation hour"),
  minResponses: Yup.number().required().label("Minimum responses"),
  maxResponses: Yup.number().required().label("Maximum responses"),
  maxQuestionDurationInHours: Yup.number().required().label("Maximum duration"),
  allowOneOnOneRequests: Yup.boolean(),
  allowPrivateQuestions: Yup.boolean(),
  canAskQuestions: Yup.boolean(),
})

export const AdminUserForm = ({
  initialValues,
  onSubmit,
}: AdminUserFormProps) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Fieldset legend="Personal details">
            <TextField label="Name" name="name" />
            <TextField label="Email" name="email" labelClassName="mt-4" />
            <TextField
              label="Company name"
              name="companyName"
              labelClassName="mt-4"
            />
            <TextField
              label="Job title"
              name="jobTitle"
              labelClassName="mt-4"
            />
            <TextField
              as="textarea"
              label="Bio"
              name="bio"
              labelClassName="mt-4"
              rows={6}
            />
          </Fieldset>

          <Fieldset legend="Settings" className="mt-8">
            <TextField
              label="Cost per response (in dollars)"
              name="costPerResponseInDollars"
              type="number"
            />
            <TextField
              labelClassName="mt-4"
              label="Cost per consultation hour (in dollars)"
              name="costPerConsultationHourInDollars"
              type="number"
            />
            <TextField
              labelClassName="mt-4"
              label="Minimum number of responses"
              name="minResponses"
              type="number"
            />
            <TextField
              labelClassName="mt-4"
              label="Maximum number of responses"
              name="maxResponses"
              type="number"
            />
            <TextField
              labelClassName="mt-4"
              label="Maximum question duration (in hours)"
              name="maxQuestionDurationInHours"
              type="number"
            />
            <CheckBoxField
              label="Allow private questions"
              name="allowPrivateQuestions"
              className="mt-4"
            />
            <CheckBoxField
              label="Allow one on one requests"
              name="allowOneOnOneRequests"
            />
          </Fieldset>

          <Fieldset legend="Permissions" className="mt-8">
            <CheckBoxField label="Admin" name="isAdmin" />
            <CheckBoxField label="Ask questions" name="canAskQuestions" />
          </Fieldset>

          <div className="mt-8">
            <Button type="submit" disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  )
}
