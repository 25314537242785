import { Link } from "react-router-dom"

interface BreadcrumbProps {
  path: string
  label?: string
  className?: string
}

export const Breadcrumb = ({ path, label, className }: BreadcrumbProps) => {
  return (
    <div className={className}>
      <Link to={path}>&lt; {label ?? "Back"}</Link>
    </div>
  )
}
