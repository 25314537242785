import { useState } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { QuestionStateEnum } from "~/__generated__/graphql"
import { useGraphQLMutation } from "~/graphql/graphql-hook"
import { displayErrors } from "~/util/validations"
import { Button } from "~/components/Button"
import { Fieldset } from "~/components/Fieldset"
import { TextField } from "~/components/FormFields/TextField"
import { useAdminQuestion } from "~/components/Admin/AdminQuestionContext"

const UPDATE_RESPONSE_REQUEST_EMAIL_MUTATION = graphql(`
  mutation UpdateResponseRequestEmailMutation(
    $input: UpdateResponseRequestEmailInput!
  ) {
    updateResponseRequestEmail(input: $input) {
      question {
        id
      }
    }
  }
`)

interface EmailValues {
  emailSubject: string
  emailBody: string
}

export const AdminQuestionRequestEmailPreview = () => {
  const { questionResult } = useAdminQuestion()
  const emailMutation = useGraphQLMutation(
    UPDATE_RESPONSE_REQUEST_EMAIL_MUTATION,
  )
  const [editEmail, setEditEmail] = useState(false)

  if (!questionResult.data) return null
  const { question } = questionResult.data

  const stateIsSubmitted = question.state === QuestionStateEnum.Submitted

  const initialValues: EmailValues = {
    emailBody: question.emailBody,
    emailSubject: question.emailSubject,
  }

  const onSubmit = (
    values: EmailValues,
    { setSubmitting, setFieldError }: FormikHelpers<EmailValues>,
  ) => {
    emailMutation.mutate(
      {
        input: {
          questionId: question.id,
          ...values,
        },
      },
      {
        onError: (error) => displayErrors(error, setFieldError),
        onSuccess: () => {
          toast.success("Email updated")
          questionResult.refetch()
          setEditEmail(false)
        },
        onSettled: () => setSubmitting(false),
      },
    )
  }

  return (
    <>
      <h2>Response request email preview</h2>
      <div className="mt-8">
        {!editEmail && (
          <div>
            <p className="mt-8">
              <strong>Subject:</strong>
              <br />
              {question.emailSubject}
            </p>

            <p className="mt-8">
              <strong>Body:</strong>
            </p>
            <div className="rendered-markdown">
              <div
                dangerouslySetInnerHTML={{ __html: question.emailBodyPreview }}
              />
              <p className="mt-4">
                P.S. If you do not want to hear questions like this, we
                apologize and <a href="/#">let us know</a>!
              </p>
            </div>
            {stateIsSubmitted && (
              <div className="mt-4">
                <Button label="Edit email" onClick={() => setEditEmail(true)} />
              </div>
            )}
          </div>
        )}

        {stateIsSubmitted && editEmail && (
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Fieldset legend="Edit email">
                  <TextField name="emailSubject" label="Subject" />
                  <TextField
                    name="emailBody"
                    as="textarea"
                    label="Body"
                    labelClassName="mt-4"
                  />
                  <small>
                    <strong>Note:</strong>{" "}
                    <span className="bg-gray-200 dark:bg-gray-600">
                      {"{{magic_link}}"}
                    </span>{" "}
                    must appear in the body or recipients will not have a way to
                    access the question response form from the email. This field
                    supports supports Markdown.
                  </small>
                </Fieldset>
                <div className="flex gap-4 mt-4">
                  <Button type="submit" label="Save & preview" />
                  <Button
                    type="button"
                    onClick={() => setEditEmail(false)}
                    label="Cancel"
                    className="bg-red-700"
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  )
}
