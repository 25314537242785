import { Fragment } from "react"

export const MultilineText = ({
  text,
  className,
}: {
  text: string
  className?: string
}) => {
  return (
    <div className={className}>
      {text.split("\n").map((item, key) => {
        return (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        )
      })}
    </div>
  )
}
