import { UseQueryResult } from "@tanstack/react-query"
import React, { createContext, useContext, useMemo } from "react"
import { graphql } from "~/__generated__"
import { CurrentUserProviderQuery } from "~/__generated__/graphql"
import { useGraphQLQuery } from "~/graphql/graphql-hook"

interface CurrentUserContextType {
  result: UseQueryResult<CurrentUserProviderQuery>
  currentUser: CurrentUserProviderQuery["currentUser"]
}

const CurrentUserContext = createContext<CurrentUserContextType | null>(null)

export const CurrentUserProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const result = useGraphQLQuery(CURRENT_USER_QUERY_DOCUMENT)
  const currentUser = result.data?.currentUser
  const value = useMemo(() => ({ result, currentUser }), [result, currentUser])

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUserMaybe = () => {
  const contextValue = useContext(CurrentUserContext)
  if (contextValue === null) {
    throw Error("Context has not been Provided!")
  }
  return contextValue
}

const CURRENT_USER_QUERY_DOCUMENT = graphql(`
  query CurrentUserProvider {
    currentUser {
      id
      canAskQuestions
    }
  }
`)
