import React from "react"
import clsx from "clsx"

type FieldLabelProps = {
  label: string
  children?: React.ReactNode
  className?: string
}

export const FieldLabel = ({ label, children, className }: FieldLabelProps) => {
  return (
    <label className={clsx("block", className)}>
      <span className="block">{label}</span>
      {children}
    </label>
  )
}
