import { useParams } from "react-router-dom"
import { graphql } from "~/__generated__"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import { TableCell } from "~/components/Admin/TableCell"
import { assert } from "~/util/assert"

export const AdminQuestionFollowupRequestsRoute = () => {
  const { questionId } = useParams()
  assert(questionId)

  const query = useGraphQLQuery(
    ADMIN_QUESTION_FOLLOWUP_REQUESTS_QUERY_DOCUMENT,
    {
      id: questionId,
    },
  )

  if (query.status === "error") return <div>error</div>
  if (query.status === "loading") return <div>loading …</div>

  const { followupRequests } = query.data.question

  return (
    <div className="overflow-x-auto mt-8">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <TableCell heading>Responder</TableCell>
            <TableCell heading>Message</TableCell>
            <TableCell heading>Availability</TableCell>
            <TableCell heading>Created</TableCell>
          </tr>
        </thead>
        <tbody>
          {followupRequests.map((fr) => (
            <tr key={fr.id}>
              <TableCell>{fr.responder.name}</TableCell>
              <TableCell>{fr.requesterMessage}</TableCell>
              <TableCell>{fr.requesterAvailability}</TableCell>
              <TableCell>{fr.createdAt}</TableCell>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const ADMIN_QUESTION_FOLLOWUP_REQUESTS_QUERY_DOCUMENT = graphql(`
  query AdminQuestionFollowupRequestsDocument($id: ID!) {
    question(id: $id) {
      followupRequests {
        id
        requesterMessage
        requesterAvailability
        createdAt
        responder {
          id
          name
        }
      }
    }
  }
`)
