import { Link } from "react-router-dom"
import { graphql } from "~/__generated__"
import { QuestionResponseRatingEnum } from "~/__generated__/graphql"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import { Button } from "~/components/Button"
import { truncatedQuestion } from "~/util/questions"
import { RateResponse } from "~/components/RateResponse"
import { MultilineText } from "./MultilineText"
import { parseResponseRating } from "~/util/parseEnums"
import { followupRequestPath } from "~/util/paths"

interface QuestionProps {
  questionId: string
}

export const Question = ({ questionId }: QuestionProps) => {
  const result = useGraphQLQuery(QUESTION_ROUTE_DOCUMENT, {
    id: questionId,
  })

  if (result.status === "error") return <div>error</div>
  if (result.status === "loading") return <div>loading …</div>

  const { question } = result.data

  return (
    <div>
      <h1>{truncatedQuestion(question.question)}</h1>
      <div className="mt-4">
        <strong>Question details:</strong>
      </div>
      <div className="mb-2">From: {question.from}</div>
      <div className="mb-2">
        To: {!!question.targetingPositive ? question.targetingPositive : "N/A"}
      </div>
      <div className="mb-2">
        Exclude:{" "}
        {!!question.targetingNegative ? question.targetingNegative : "N/A"}
      </div>
      <div className="mb-4">
        Disclaimer: {!!question.disclaimer ? question.disclaimer : "N/A"}
      </div>
      <MultilineText className="mb-4" text={question.question} />
      {!question.closedAt && (
        <Button type="button" className="w-auto mt-4" label="Close question" />
      )}
      <hr className="my-8" />
      <h2>Responses</h2>
      {question.responses.length ? (
        question.responses.map((response) => (
          <div className="mt-4" key={response.id}>
            <div>
              <strong>From</strong>
              <div>{response.from}</div>
            </div>
            <div className="mt-4">
              <strong>Response</strong>
              <div>{response.responseText}</div>
            </div>
            <div className="mt-4 sm:flex justify-between">
              <div>
                Rating:{" "}
                {!!response.requesterRating ? (
                  parseResponseRating(response.requesterRating)
                ) : (
                  <>
                    <RateResponse
                      questionRequestId={response.id}
                      label="Great"
                      rating={QuestionResponseRatingEnum.Good}
                      onSuccess={() => result.refetch()}
                    />{" "}
                    |{" "}
                    <RateResponse
                      questionRequestId={response.id}
                      label="Good"
                      rating={QuestionResponseRatingEnum.Neutral}
                      onSuccess={() => result.refetch()}
                    />{" "}
                    |{" "}
                    <RateResponse
                      questionRequestId={response.id}
                      label="Not good"
                      rating={QuestionResponseRatingEnum.Bad}
                      onSuccess={() => result.refetch()}
                    />
                  </>
                )}
              </div>
              {response.openToOneOnOne && (
                <>
                  {response.hasFollowupRequest ? (
                    "1:1 Scheduled"
                  ) : (
                    <Link
                      to={followupRequestPath({
                        questionRequestId: response.id,
                      })}
                    >
                      Schedule 1:1
                    </Link>
                  )}
                </>
              )}
            </div>
            <hr className="my-4" />
          </div>
        ))
      ) : (
        <p>Hold tight, we're still waiting for some responses.</p>
      )}
    </div>
  )
}

export const QUESTION_ROUTE_DOCUMENT = graphql(`
  query QuestionRoute($id: ID!) {
    question(id: $id) {
      id
      question
      closedAt
      from
      disclaimer
      public
      requestOneOnOne
      targetingPositive
      targetingNegative
      responses {
        id
        from
        responseText
        openToOneOnOne
        requesterRating
        hasFollowupRequest
      }
    }
  }
`)
