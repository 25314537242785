import { Formik, Form, FormikHelpers } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import { Button } from "~/components/Button"
import { Fieldset } from "~/components/Fieldset"
import { TextField } from "~/components/FormFields/TextField"
import { graphql } from "~/__generated__"
import { useGraphQLQuery, useGraphQLMutation } from "~/graphql/graphql-hook"
import { displayErrors } from "~/util/validations"
import { assert } from "~/util/assert"

type CurrentUserValues = {
  name: string
  companyName?: string
  jobTitle?: string
  bio?: string
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
})

export const SettingsRoute = () => {
  const result = useGraphQLQuery(SETTINGS_QUERY_DOCUMENT)
  const mutation = useGraphQLMutation(UPDATE_CURRENT_USER_MUTATION)

  if (result.status === "error") return <div>error</div>
  if (result.status === "loading") return <div>loading …</div>

  const { currentUser } = result.data
  assert(currentUser)

  const initialValues: CurrentUserValues = {
    name: currentUser.name ?? "",
    companyName: currentUser.companyName ?? "",
    jobTitle: currentUser.jobTitle ?? "",
    bio: currentUser.bio ?? "",
  }

  const onSubmit = (
    values: CurrentUserValues,
    { setSubmitting, setFieldError }: FormikHelpers<CurrentUserValues>,
  ) => {
    mutation.mutate(
      {
        input: {
          ...values,
        },
      },
      {
        onSuccess: () => {
          toast.success("Settings updated")
          result.refetch()
        },
        onError: (error) => {
          displayErrors(error, setFieldError)
        },
        onSettled: () => {
          setSubmitting(false)
        },
      },
    )
  }

  return (
    <div className="max-w-3xl m-auto">
      <h1>Settings</h1>

      <div className="my-4">
        <p>
          <strong>Your email:</strong> {currentUser.email}
        </p>
        <p>
          <strong>Your cost per response:</strong> $
          {currentUser.costPerResponseInDollars}
        </p>
        <p>
          <strong>Your hourly rate for consultations:</strong> $
          {currentUser.costPerConsultationHourInDollars}
        </p>
        <p>
          <strong>Allowed to make questions private:</strong>{" "}
          {currentUser.allowPrivateQuestions ? "Yes" : "No"}
        </p>
        <p>
          <strong>Allowed to request 1:1s:</strong>{" "}
          {currentUser.allowOneOnOneRequests ? "Yes" : "No"}
        </p>
      </div>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Fieldset legend="Personal details">
              <TextField label="Name" name="name" />
              <TextField
                label="Company name"
                name="companyName"
                labelClassName="mt-4"
              />
              <TextField
                label="Job title"
                name="jobTitle"
                labelClassName="mt-4"
              />
              <TextField
                as="textarea"
                label="Bio"
                name="bio"
                labelClassName="mt-4"
                rows={6}
              />
            </Fieldset>

            <div className="mt-8">
              <Button
                type="submit"
                disabled={isSubmitting}
                label="Update settings"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const SETTINGS_QUERY_DOCUMENT = graphql(`
  query SettingsRoute {
    currentUser {
      id
      name
      email
      companyName
      jobTitle
      bio
      costPerResponseInDollars
      costPerConsultationHourInDollars
      allowPrivateQuestions
      allowOneOnOneRequests
    }
  }
`)

const UPDATE_CURRENT_USER_MUTATION = graphql(`
  mutation UpdateCurrentUserMutation($input: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      currentUser {
        id
      }
    }
  }
`)
