import React from "react"
import clsx from "clsx"

interface TableCellProps {
  children: React.ReactNode
  heading?: boolean
  className?: string
  hideOnMobile?: boolean
}

export const TableCell = ({
  className,
  children,
  heading,
  hideOnMobile,
}: TableCellProps) => {
  return (
    <td
      className={clsx(
        "border border-black p-2 dark:border-white",
        className,
        heading && "font-bold bg-slate-200 dark:bg-gray-800 align-top",
        hideOnMobile && "hidden sm:table-cell",
      )}
    >
      {children}
    </td>
  )
}
