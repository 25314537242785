import { ButtonHTMLAttributes } from "react"
import { useNavigate } from "react-router-dom"
import clsx from "clsx"

type ButtonProps = {
  label?: string
  href?: string
} & ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({
  label,
  disabled,
  type = "button",
  className,
  href,
  ...buttonProps
}: ButtonProps) => {
  const navigate = useNavigate()

  const onClick = () => {
    if (!href) return
    navigate(href)
  }

  return (
    <button
      className={clsx("btn w-full md:w-auto", className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...buttonProps}
    >
      {label ?? "Submit"}
    </button>
  )
}
