/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation AdminAddIdentitiesToQuestion($input: AddIdentitiesToQuestionInput!) {\n    adminAddIdentitiesToQuestion(input: $input) {\n      successMessage\n    }\n  }\n": types.AdminAddIdentitiesToQuestionDocument,
    "\n  mutation OpenQuestion($input: OpenQuestionInput!) {\n    openQuestion(input: $input) {\n      question {\n        id\n        state\n      }\n    }\n  }\n": types.OpenQuestionDocument,
    "\n  mutation CloseQuestion($input: CloseQuestionInput!) {\n    closeQuestion(input: $input) {\n      question {\n        id\n        state\n      }\n    }\n  }\n": types.CloseQuestionDocument,
    "\n  mutation RejectQuestion($input: RejectQuestionInput!) {\n    rejectQuestion(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n": types.RejectQuestionDocument,
    "\n  mutation UpdateResponseRequestEmailMutation(\n    $input: UpdateResponseRequestEmailInput!\n  ) {\n    updateResponseRequestEmail(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n": types.UpdateResponseRequestEmailMutationDocument,
    "\n  mutation AdminApproveResponse($input: AdminApproveResponseInput!) {\n    adminApproveResponse(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n": types.AdminApproveResponseDocument,
    "\n  fragment Question_AdminHeader on Question {\n    id\n    question\n    user {\n      id\n      name\n    }\n    createdAt\n  }\n": types.Question_AdminHeaderFragmentDoc,
    "\n  query QuestionRoute($id: ID!) {\n    question(id: $id) {\n      id\n      question\n      closedAt\n      from\n      disclaimer\n      public\n      requestOneOnOne\n      targetingPositive\n      targetingNegative\n      responses {\n        id\n        from\n        responseText\n        openToOneOnOne\n        requesterRating\n        hasFollowupRequest\n      }\n    }\n  }\n": types.QuestionRouteDocument,
    "\n  mutation QuestionResponseAddUserDetails(\n    $input: QuestionResponseAddUserDetailsInput!\n  ) {\n    questionResponseAddUserDetails(input: $input) {\n      clientMutationId\n    }\n  }\n": types.QuestionResponseAddUserDetailsDocument,
    "\n  mutation RateQuestionResponseMutation($input: RateQuestionResponseInput!) {\n    rateQuestionResponse(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n": types.RateQuestionResponseMutationDocument,
    "\n  query CurrentUserProvider {\n    currentUser {\n      id\n      canAskQuestions\n    }\n  }\n": types.CurrentUserProviderDocument,
    "\n  query QuestionRequestDocument($id: ID!) {\n    questionRequest(id: $id) {\n      id\n      from\n      question {\n        id\n      }\n    }\n  }\n": types.QuestionRequestDocumentDocument,
    "\n  mutation CreateFollowupRequest($input: CreateFollowupRequestInput!) {\n    createFollowupRequest(input: $input) {\n      followupRequest {\n        id\n      }\n    }\n  }\n": types.CreateFollowupRequestDocument,
    "\n  mutation QuestionCreate($input: QuestionCreateInput!) {\n    questionCreate(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n": types.QuestionCreateDocument,
    "\n  query QuestionCreateCurrentUser {\n    currentUser {\n      id\n      name\n      email\n      jobTitle\n      companyName\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      minResponses\n      maxResponses\n      maxQuestionDurationInHours\n      allowOneOnOneRequests\n      allowPrivateQuestions\n      status\n    }\n  }\n": types.QuestionCreateCurrentUserDocument,
    "\n  query NewQuestionDocument($id: ID!) {\n    question(id: $id) {\n      id\n      screeningCompletedAt\n      userPickedSuggestionsAt\n      targetingPositive\n      targetingNegative\n    }\n  }\n": types.NewQuestionDocumentDocument,
    "\n  query QuestionSuggestionsQueryDocument($questionId: ID!) {\n    questionRequests(questionId: $questionId) {\n      nodes {\n        id\n        aiScreeningReasoning\n        sources\n        identityProfile {\n          id\n          name\n          bio\n          companyName\n          jobTitle\n        }\n      }\n    }\n  }\n": types.QuestionSuggestionsQueryDocumentDocument,
    "\n  mutation SuggestionAcceptReject($input: SuggestionAcceptRejectInput!) {\n    suggestionAcceptReject(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n": types.SuggestionAcceptRejectDocument,
    "\n  mutation RequesterCompletedSuggestionPicks(\n    $input: RequesterCompletedSuggestionPicksInput!\n  ) {\n    requesterCompletedSuggestionPicks(input: $input) {\n      question {\n        id\n        userPickedSuggestionsAt\n      }\n    }\n  }\n": types.RequesterCompletedSuggestionPicksDocument,
    "\n  mutation QuestionResponseCreate($input: QuestionResponseCreateInput!) {\n    questionResponseCreate(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n": types.QuestionResponseCreateDocument,
    "\n  query ResponseQuestionDocument($id: ID!) {\n    question(id: $id) {\n      id\n      question\n      from\n      disclaimer\n      public\n      requestOneOnOne\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n    }\n  }\n": types.ResponseQuestionDocumentDocument,
    "\n  query QuestionsRoute {\n    questions {\n      nodes {\n        id\n        question\n        closedAt\n      }\n    }\n  }\n": types.QuestionsRouteDocument,
    "\n  query SettingsRoute {\n    currentUser {\n      id\n      name\n      email\n      companyName\n      jobTitle\n      bio\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      allowPrivateQuestions\n      allowOneOnOneRequests\n    }\n  }\n": types.SettingsRouteDocument,
    "\n  mutation UpdateCurrentUserMutation($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n      }\n    }\n  }\n": types.UpdateCurrentUserMutationDocument,
    "\n  query AdminFollowupRequestsRoute {\n    adminFollowupRequests {\n      nodes {\n        id\n        requesterMessage\n        requesterAvailability\n        createdAt\n        questionRequest {\n          id\n        }\n        question {\n          id\n          question\n        }\n        responder {\n          id\n          name\n        }\n        requester {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminFollowupRequestsRouteDocument,
    "\n  query AdminLayout {\n    adminResponses {\n      totalCount\n    }\n    adminFollowupRequests {\n      totalCount\n    }\n  }\n": types.AdminLayoutDocument,
    "\n  query AdminQuestionFollowupRequestsDocument($id: ID!) {\n    question(id: $id) {\n      followupRequests {\n        id\n        requesterMessage\n        requesterAvailability\n        createdAt\n        responder {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminQuestionFollowupRequestsDocumentDocument,
    "\n  query AdminQuestionLayout($id: ID!) {\n    question(id: $id) {\n      ...Question_AdminHeader\n\n      id\n      question\n      from\n      emailSubject\n      emailBody\n      emailBodyPreview\n      requestOneOnOne\n      numberOfResponses\n      durationInHours\n      state\n      rejectionNotes\n      rejectionSentAt\n      createdAt\n\n      user {\n        id\n        name\n      }\n    }\n  }\n": types.AdminQuestionLayoutDocument,
    "\n  query AdminQuestionRequestRoute($id: ID!) {\n    questionRequest(id: $id) {\n      id\n      responseText\n      from\n      openToOneOnOne\n      privacyStatus\n      state\n      respondedAt\n\n      question {\n        id\n        question\n      }\n    }\n  }\n": types.AdminQuestionRequestRouteDocument,
    "\n  mutation AdminSendQuestionsToRespondents(\n    $input: AdminSendQuestionToRespondentsInput!\n  ) {\n    adminSendQuestionToRespondents(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n": types.AdminSendQuestionsToRespondentsDocument,
    "\n  mutation RejectQuestionRequests($input: RejectQuestionRequestsInput!) {\n    rejectQuestionRequests(input: $input) {\n      clientMutationId\n    }\n  }\n": types.RejectQuestionRequestsDocument,
    "\n  mutation AdminRerunAiScreening($input: RerunAiScreeningInput!) {\n    adminRerunAiScreening(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n": types.AdminRerunAiScreeningDocument,
    "\n  query AdminQuestionRespondents($questionId: ID!) {\n    question(id: $questionId) {\n      id\n      targetingPositive\n      targetingNegative\n      enhancedQuery\n    }\n\n    adminRequestsForQuestion(questionId: $questionId) {\n      nodes {\n        id\n        relevancyScore\n        state\n        sources\n        aiScreeningReasoning\n\n        identityProfile {\n          id\n          externalId\n          email\n          name\n          bio\n          bioLong\n        }\n      }\n    }\n  }\n": types.AdminQuestionRespondentsDocument,
    "\n  query AdminQuestionsRoute {\n    adminQuestions {\n      nodes {\n        id\n        question\n        createdAt\n        state\n      }\n    }\n  }\n": types.AdminQuestionsRouteDocument,
    "\n  query AdminResponsesRoute {\n    adminResponses {\n      nodes {\n        id\n        responseText\n        respondedAt\n        from\n        state\n        privacyStatus\n        openToOneOnOne\n        question {\n          id\n          question\n        }\n        responder {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminResponsesRouteDocument,
    "\n  mutation CreateUserMutation($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.CreateUserMutationDocument,
    "\n  query AdminUserRoute($userId: ID!) {\n    adminUser(userId: $userId) {\n      id\n      email\n      name\n      name\n      companyName\n      jobTitle\n      bio\n      isAdmin\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      minResponses\n      maxResponses\n      maxQuestionDurationInHours\n      allowOneOnOneRequests\n      allowPrivateQuestions\n      canAskQuestions\n      status\n    }\n  }\n": types.AdminUserRouteDocument,
    "\n  mutation UpdateUserMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        email\n        name\n        companyName\n        jobTitle\n        bio\n        isAdmin\n        costPerResponseInDollars\n        costPerConsultationHourInDollars\n        minResponses\n        maxResponses\n        maxQuestionDurationInHours\n        allowOneOnOneRequests\n        allowPrivateQuestions\n        canAskQuestions\n      }\n    }\n  }\n": types.UpdateUserMutationDocument,
    "\n  query AdminUsersRoute {\n    adminUsers {\n      nodes {\n        id\n        name\n        email\n        companyName\n        isAdmin\n        status\n      }\n    }\n  }\n": types.AdminUsersRouteDocument,
    "\n  mutation UpdateUserStatus($input: UpdateUserStatusInput!) {\n    updateUserStatus(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.UpdateUserStatusDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminAddIdentitiesToQuestion($input: AddIdentitiesToQuestionInput!) {\n    adminAddIdentitiesToQuestion(input: $input) {\n      successMessage\n    }\n  }\n"): (typeof documents)["\n  mutation AdminAddIdentitiesToQuestion($input: AddIdentitiesToQuestionInput!) {\n    adminAddIdentitiesToQuestion(input: $input) {\n      successMessage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OpenQuestion($input: OpenQuestionInput!) {\n    openQuestion(input: $input) {\n      question {\n        id\n        state\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OpenQuestion($input: OpenQuestionInput!) {\n    openQuestion(input: $input) {\n      question {\n        id\n        state\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CloseQuestion($input: CloseQuestionInput!) {\n    closeQuestion(input: $input) {\n      question {\n        id\n        state\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CloseQuestion($input: CloseQuestionInput!) {\n    closeQuestion(input: $input) {\n      question {\n        id\n        state\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RejectQuestion($input: RejectQuestionInput!) {\n    rejectQuestion(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RejectQuestion($input: RejectQuestionInput!) {\n    rejectQuestion(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateResponseRequestEmailMutation(\n    $input: UpdateResponseRequestEmailInput!\n  ) {\n    updateResponseRequestEmail(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateResponseRequestEmailMutation(\n    $input: UpdateResponseRequestEmailInput!\n  ) {\n    updateResponseRequestEmail(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminApproveResponse($input: AdminApproveResponseInput!) {\n    adminApproveResponse(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminApproveResponse($input: AdminApproveResponseInput!) {\n    adminApproveResponse(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Question_AdminHeader on Question {\n    id\n    question\n    user {\n      id\n      name\n    }\n    createdAt\n  }\n"): (typeof documents)["\n  fragment Question_AdminHeader on Question {\n    id\n    question\n    user {\n      id\n      name\n    }\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query QuestionRoute($id: ID!) {\n    question(id: $id) {\n      id\n      question\n      closedAt\n      from\n      disclaimer\n      public\n      requestOneOnOne\n      targetingPositive\n      targetingNegative\n      responses {\n        id\n        from\n        responseText\n        openToOneOnOne\n        requesterRating\n        hasFollowupRequest\n      }\n    }\n  }\n"): (typeof documents)["\n  query QuestionRoute($id: ID!) {\n    question(id: $id) {\n      id\n      question\n      closedAt\n      from\n      disclaimer\n      public\n      requestOneOnOne\n      targetingPositive\n      targetingNegative\n      responses {\n        id\n        from\n        responseText\n        openToOneOnOne\n        requesterRating\n        hasFollowupRequest\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation QuestionResponseAddUserDetails(\n    $input: QuestionResponseAddUserDetailsInput!\n  ) {\n    questionResponseAddUserDetails(input: $input) {\n      clientMutationId\n    }\n  }\n"): (typeof documents)["\n  mutation QuestionResponseAddUserDetails(\n    $input: QuestionResponseAddUserDetailsInput!\n  ) {\n    questionResponseAddUserDetails(input: $input) {\n      clientMutationId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RateQuestionResponseMutation($input: RateQuestionResponseInput!) {\n    rateQuestionResponse(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RateQuestionResponseMutation($input: RateQuestionResponseInput!) {\n    rateQuestionResponse(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CurrentUserProvider {\n    currentUser {\n      id\n      canAskQuestions\n    }\n  }\n"): (typeof documents)["\n  query CurrentUserProvider {\n    currentUser {\n      id\n      canAskQuestions\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query QuestionRequestDocument($id: ID!) {\n    questionRequest(id: $id) {\n      id\n      from\n      question {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query QuestionRequestDocument($id: ID!) {\n    questionRequest(id: $id) {\n      id\n      from\n      question {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFollowupRequest($input: CreateFollowupRequestInput!) {\n    createFollowupRequest(input: $input) {\n      followupRequest {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateFollowupRequest($input: CreateFollowupRequestInput!) {\n    createFollowupRequest(input: $input) {\n      followupRequest {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation QuestionCreate($input: QuestionCreateInput!) {\n    questionCreate(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation QuestionCreate($input: QuestionCreateInput!) {\n    questionCreate(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query QuestionCreateCurrentUser {\n    currentUser {\n      id\n      name\n      email\n      jobTitle\n      companyName\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      minResponses\n      maxResponses\n      maxQuestionDurationInHours\n      allowOneOnOneRequests\n      allowPrivateQuestions\n      status\n    }\n  }\n"): (typeof documents)["\n  query QuestionCreateCurrentUser {\n    currentUser {\n      id\n      name\n      email\n      jobTitle\n      companyName\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      minResponses\n      maxResponses\n      maxQuestionDurationInHours\n      allowOneOnOneRequests\n      allowPrivateQuestions\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query NewQuestionDocument($id: ID!) {\n    question(id: $id) {\n      id\n      screeningCompletedAt\n      userPickedSuggestionsAt\n      targetingPositive\n      targetingNegative\n    }\n  }\n"): (typeof documents)["\n  query NewQuestionDocument($id: ID!) {\n    question(id: $id) {\n      id\n      screeningCompletedAt\n      userPickedSuggestionsAt\n      targetingPositive\n      targetingNegative\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query QuestionSuggestionsQueryDocument($questionId: ID!) {\n    questionRequests(questionId: $questionId) {\n      nodes {\n        id\n        aiScreeningReasoning\n        sources\n        identityProfile {\n          id\n          name\n          bio\n          companyName\n          jobTitle\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query QuestionSuggestionsQueryDocument($questionId: ID!) {\n    questionRequests(questionId: $questionId) {\n      nodes {\n        id\n        aiScreeningReasoning\n        sources\n        identityProfile {\n          id\n          name\n          bio\n          companyName\n          jobTitle\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SuggestionAcceptReject($input: SuggestionAcceptRejectInput!) {\n    suggestionAcceptReject(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SuggestionAcceptReject($input: SuggestionAcceptRejectInput!) {\n    suggestionAcceptReject(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequesterCompletedSuggestionPicks(\n    $input: RequesterCompletedSuggestionPicksInput!\n  ) {\n    requesterCompletedSuggestionPicks(input: $input) {\n      question {\n        id\n        userPickedSuggestionsAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RequesterCompletedSuggestionPicks(\n    $input: RequesterCompletedSuggestionPicksInput!\n  ) {\n    requesterCompletedSuggestionPicks(input: $input) {\n      question {\n        id\n        userPickedSuggestionsAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation QuestionResponseCreate($input: QuestionResponseCreateInput!) {\n    questionResponseCreate(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation QuestionResponseCreate($input: QuestionResponseCreateInput!) {\n    questionResponseCreate(input: $input) {\n      questionRequest {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ResponseQuestionDocument($id: ID!) {\n    question(id: $id) {\n      id\n      question\n      from\n      disclaimer\n      public\n      requestOneOnOne\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n    }\n  }\n"): (typeof documents)["\n  query ResponseQuestionDocument($id: ID!) {\n    question(id: $id) {\n      id\n      question\n      from\n      disclaimer\n      public\n      requestOneOnOne\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query QuestionsRoute {\n    questions {\n      nodes {\n        id\n        question\n        closedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query QuestionsRoute {\n    questions {\n      nodes {\n        id\n        question\n        closedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SettingsRoute {\n    currentUser {\n      id\n      name\n      email\n      companyName\n      jobTitle\n      bio\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      allowPrivateQuestions\n      allowOneOnOneRequests\n    }\n  }\n"): (typeof documents)["\n  query SettingsRoute {\n    currentUser {\n      id\n      name\n      email\n      companyName\n      jobTitle\n      bio\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      allowPrivateQuestions\n      allowOneOnOneRequests\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCurrentUserMutation($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCurrentUserMutation($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      currentUser {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminFollowupRequestsRoute {\n    adminFollowupRequests {\n      nodes {\n        id\n        requesterMessage\n        requesterAvailability\n        createdAt\n        questionRequest {\n          id\n        }\n        question {\n          id\n          question\n        }\n        responder {\n          id\n          name\n        }\n        requester {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminFollowupRequestsRoute {\n    adminFollowupRequests {\n      nodes {\n        id\n        requesterMessage\n        requesterAvailability\n        createdAt\n        questionRequest {\n          id\n        }\n        question {\n          id\n          question\n        }\n        responder {\n          id\n          name\n        }\n        requester {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminLayout {\n    adminResponses {\n      totalCount\n    }\n    adminFollowupRequests {\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query AdminLayout {\n    adminResponses {\n      totalCount\n    }\n    adminFollowupRequests {\n      totalCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminQuestionFollowupRequestsDocument($id: ID!) {\n    question(id: $id) {\n      followupRequests {\n        id\n        requesterMessage\n        requesterAvailability\n        createdAt\n        responder {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminQuestionFollowupRequestsDocument($id: ID!) {\n    question(id: $id) {\n      followupRequests {\n        id\n        requesterMessage\n        requesterAvailability\n        createdAt\n        responder {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminQuestionLayout($id: ID!) {\n    question(id: $id) {\n      ...Question_AdminHeader\n\n      id\n      question\n      from\n      emailSubject\n      emailBody\n      emailBodyPreview\n      requestOneOnOne\n      numberOfResponses\n      durationInHours\n      state\n      rejectionNotes\n      rejectionSentAt\n      createdAt\n\n      user {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminQuestionLayout($id: ID!) {\n    question(id: $id) {\n      ...Question_AdminHeader\n\n      id\n      question\n      from\n      emailSubject\n      emailBody\n      emailBodyPreview\n      requestOneOnOne\n      numberOfResponses\n      durationInHours\n      state\n      rejectionNotes\n      rejectionSentAt\n      createdAt\n\n      user {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminQuestionRequestRoute($id: ID!) {\n    questionRequest(id: $id) {\n      id\n      responseText\n      from\n      openToOneOnOne\n      privacyStatus\n      state\n      respondedAt\n\n      question {\n        id\n        question\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminQuestionRequestRoute($id: ID!) {\n    questionRequest(id: $id) {\n      id\n      responseText\n      from\n      openToOneOnOne\n      privacyStatus\n      state\n      respondedAt\n\n      question {\n        id\n        question\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminSendQuestionsToRespondents(\n    $input: AdminSendQuestionToRespondentsInput!\n  ) {\n    adminSendQuestionToRespondents(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminSendQuestionsToRespondents(\n    $input: AdminSendQuestionToRespondentsInput!\n  ) {\n    adminSendQuestionToRespondents(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RejectQuestionRequests($input: RejectQuestionRequestsInput!) {\n    rejectQuestionRequests(input: $input) {\n      clientMutationId\n    }\n  }\n"): (typeof documents)["\n  mutation RejectQuestionRequests($input: RejectQuestionRequestsInput!) {\n    rejectQuestionRequests(input: $input) {\n      clientMutationId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminRerunAiScreening($input: RerunAiScreeningInput!) {\n    adminRerunAiScreening(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminRerunAiScreening($input: RerunAiScreeningInput!) {\n    adminRerunAiScreening(input: $input) {\n      question {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminQuestionRespondents($questionId: ID!) {\n    question(id: $questionId) {\n      id\n      targetingPositive\n      targetingNegative\n      enhancedQuery\n    }\n\n    adminRequestsForQuestion(questionId: $questionId) {\n      nodes {\n        id\n        relevancyScore\n        state\n        sources\n        aiScreeningReasoning\n\n        identityProfile {\n          id\n          externalId\n          email\n          name\n          bio\n          bioLong\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminQuestionRespondents($questionId: ID!) {\n    question(id: $questionId) {\n      id\n      targetingPositive\n      targetingNegative\n      enhancedQuery\n    }\n\n    adminRequestsForQuestion(questionId: $questionId) {\n      nodes {\n        id\n        relevancyScore\n        state\n        sources\n        aiScreeningReasoning\n\n        identityProfile {\n          id\n          externalId\n          email\n          name\n          bio\n          bioLong\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminQuestionsRoute {\n    adminQuestions {\n      nodes {\n        id\n        question\n        createdAt\n        state\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminQuestionsRoute {\n    adminQuestions {\n      nodes {\n        id\n        question\n        createdAt\n        state\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminResponsesRoute {\n    adminResponses {\n      nodes {\n        id\n        responseText\n        respondedAt\n        from\n        state\n        privacyStatus\n        openToOneOnOne\n        question {\n          id\n          question\n        }\n        responder {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminResponsesRoute {\n    adminResponses {\n      nodes {\n        id\n        responseText\n        respondedAt\n        from\n        state\n        privacyStatus\n        openToOneOnOne\n        question {\n          id\n          question\n        }\n        responder {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateUserMutation($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUserMutation($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminUserRoute($userId: ID!) {\n    adminUser(userId: $userId) {\n      id\n      email\n      name\n      name\n      companyName\n      jobTitle\n      bio\n      isAdmin\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      minResponses\n      maxResponses\n      maxQuestionDurationInHours\n      allowOneOnOneRequests\n      allowPrivateQuestions\n      canAskQuestions\n      status\n    }\n  }\n"): (typeof documents)["\n  query AdminUserRoute($userId: ID!) {\n    adminUser(userId: $userId) {\n      id\n      email\n      name\n      name\n      companyName\n      jobTitle\n      bio\n      isAdmin\n      costPerResponseInDollars\n      costPerConsultationHourInDollars\n      minResponses\n      maxResponses\n      maxQuestionDurationInHours\n      allowOneOnOneRequests\n      allowPrivateQuestions\n      canAskQuestions\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        email\n        name\n        companyName\n        jobTitle\n        bio\n        isAdmin\n        costPerResponseInDollars\n        costPerConsultationHourInDollars\n        minResponses\n        maxResponses\n        maxQuestionDurationInHours\n        allowOneOnOneRequests\n        allowPrivateQuestions\n        canAskQuestions\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        email\n        name\n        companyName\n        jobTitle\n        bio\n        isAdmin\n        costPerResponseInDollars\n        costPerConsultationHourInDollars\n        minResponses\n        maxResponses\n        maxQuestionDurationInHours\n        allowOneOnOneRequests\n        allowPrivateQuestions\n        canAskQuestions\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminUsersRoute {\n    adminUsers {\n      nodes {\n        id\n        name\n        email\n        companyName\n        isAdmin\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminUsersRoute {\n    adminUsers {\n      nodes {\n        id\n        name\n        email\n        companyName\n        isAdmin\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserStatus($input: UpdateUserStatusInput!) {\n    updateUserStatus(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserStatus($input: UpdateUserStatusInput!) {\n    updateUserStatus(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;