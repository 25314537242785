import React from "react"

type FieldsetProps = {
  legend?: string
  className?: string
  children: React.ReactNode
}

export const Fieldset = ({ legend, children, className }: FieldsetProps) => {
  return (
    <fieldset className={className}>
      {legend && <legend className="font-bold">&gt; {legend}</legend>}
      <div className="border-black dark:border-white border-l pl-4">
        {children}
      </div>
    </fieldset>
  )
}
