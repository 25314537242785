import { path } from "static-path"

export const settingsPath = "/settings"
export const questionsPath = "/questions"
export const newQuestionPath = "/questions/new"
export const pickSuggestionsPath = path(
  "/questions/:questionId/pick_suggestions",
)
export const questionPath = path("/questions/:questionId")
export const respondPath = path("/questions/:questionId/respond")
export const followupRequestPath = path(
  "/question_request/:questionRequestId/followup",
)

export const adminPath = "/admin"

export const adminQuestionsPath = "/admin/questions"
export const adminQuestionPath = path("/admin/questions/:questionId")
export const adminQuestionRespondentsPath = path(
  "/admin/questions/:questionId/respondents",
)
export const adminQuestionFollowupRequestsPath = path(
  "/admin/questions/:questionId/followup_requests",
)
export const adminQuestionRequestPath = path(
  "/admin/questions/:questionId/requests/:requestId",
)

export const adminFollowupRequestsPath = "/admin/followup_requests"
export const adminResponsesPath = "/admin/responses"

export const adminUsersPath = "/admin/users"
export const adminUserUpdatePath = path("/admin/users/:userId/edit")
export const adminUserCreatePath = "/admin/users/new"

export const respondentsCsvPath = path("/admin/questions/:id/respondents_csv")

// External
export const newsletterCmsIdentityUrl = (id: string) =>
  `https://newsletter.theinformation.com/crm/${id}`
export const newsletterCmsDocumentUrl = (id: string) =>
  `https://newsletter.theinformation.com/crm/identities/${id}/people_search_document`
