import { ChangeEvent } from "react"
import { toast } from "react-hot-toast"
import Papa from "papaparse"
import { get, lowerCase, compact } from "lodash"
import { useGraphQLMutation } from "~/graphql/graphql-hook"
import { displayErrors } from "~/util/validations"
import { ADMIN_ADD_IDENTITY_DOCUMENT } from "./AdminAddRespondent"
import { FieldLabel } from "~/components/FieldLabel"

export const AdminAddRespondentsCsv = ({
  questionId,
  reloadRespondents,
}: {
  questionId: string
  reloadRespondents: () => void
}) => {
  const addIdentityMutation = useGraphQLMutation(ADMIN_ADD_IDENTITY_DOCUMENT)

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => lowerCase(header),
      complete: (rows) => {
        const emails = compact(rows.data.map((row) => get(row, "email", "")))
        submit(emails)
      },
    })
  }

  const submit = (identityEmails: string[]) => {
    addIdentityMutation.mutate(
      { input: { questionId, identityEmailsOrIds: identityEmails } },
      {
        onError: (error) => displayErrors(error),
        onSuccess: (data) => {
          if (data.adminAddIdentitiesToQuestion?.successMessage) {
            reloadRespondents()
            toast.success(data.adminAddIdentitiesToQuestion.successMessage)
          }
        },
      },
    )
  }

  return (
    <FieldLabel label="Upload CSV with emails">
      {addIdentityMutation.isLoading ? (
        <div className="py-2 mt-2">processing …</div>
      ) : (
        <input
          type="file"
          name="file"
          accept=".csv"
          onChange={onChange}
          disabled={addIdentityMutation.isLoading}
          className="border border-black border-dashed p-2 mt-2"
        />
      )}
    </FieldLabel>
  )
}
