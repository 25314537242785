import { useParams } from "react-router-dom"
import { graphql } from "~/__generated__"
import { ApproveResponseAction } from "~/components/Admin/ApproveResponseAction"
import { MultilineText } from "~/components/MultilineText"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import { assert } from "~/util/assert"

export const AdminQuestionRequestRoute = () => {
  const { questionId, requestId } = useParams<{
    questionId: string
    requestId: string
  }>()
  assert(questionId)
  assert(requestId)

  const queryResult = useGraphQLQuery(ADMIN_REQUEST_QUERY_DOCUMENT, {
    id: requestId,
  })

  if (queryResult.status === "error") return <div>error</div>
  if (queryResult.status === "loading") return <div>loading …</div>

  const request = queryResult.data.questionRequest

  return (
    <div>
      <div className="flex flex-wrap gap-x-8 mb-8">
        <div>
          <strong>State:</strong> {request.state}
        </div>
        <div>
          <strong>Respondent open to one-on-one:</strong>{" "}
          {request.openToOneOnOne ? "Yes" : "No"}
        </div>
        <div>
          <strong>Privacy:</strong> {request.privacyStatus}
        </div>
        <div>
          <strong>Responded At:</strong> {request.respondedAt || "n/a"}
        </div>
      </div>

      <div className="mb-8">
        <ApproveResponseAction
          requestId={request.id}
          requestState={request.state}
          onSuccess={() => queryResult.refetch()}
        />
      </div>

      <div className="mb-2 font-bold">Question:</div>
      <MultilineText className="mb-8" text={request.question.question} />

      <div className="mb-2 font-bold">Response:</div>
      <MultilineText
        className="mb-8"
        text={request.responseText || "no response yet"}
      />

      <div className="mb-2 font-bold">From:</div>
      <MultilineText className="mb-8" text={request.from || "n/a"} />
    </div>
  )
}

const ADMIN_REQUEST_QUERY_DOCUMENT = graphql(`
  query AdminQuestionRequestRoute($id: ID!) {
    questionRequest(id: $id) {
      id
      responseText
      from
      openToOneOnOne
      privacyStatus
      state
      respondedAt

      question {
        id
        question
      }
    }
  }
`)
