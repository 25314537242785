import { Link } from "react-router-dom"
import { graphql } from "~/__generated__"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import {
  adminQuestionPath,
  adminUserUpdatePath,
  adminQuestionRequestPath,
} from "~/util/paths"
import { TableCell } from "~/components/Admin/TableCell"
import { truncatedQuestion } from "~/util/questions"

export const AdminFollowupRequestsRoute = () => {
  const result = useGraphQLQuery(ADMIN_FOLLOWUP_REQUESTS_QUERY_DOCUMENT)

  if (result.status === "error") return <div>error</div>
  if (result.status === "loading") return <div>loading …</div>

  if (result.data.adminFollowupRequests.nodes.length === 0)
    return (
      <div className="text-2xl text-center">
        No followups have been requested yet.
      </div>
    )

  return (
    <>
      <h1>Followup Requests</h1>
      <div className="overflow-x-auto mt-8">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <TableCell heading>Question</TableCell>
              <TableCell heading>Requester</TableCell>
              <TableCell heading>Responder</TableCell>
              <TableCell heading>Message</TableCell>
              <TableCell heading>Availability</TableCell>
              <TableCell heading>Created</TableCell>
            </tr>
          </thead>
          <tbody>
            {result.data.adminFollowupRequests.nodes.map((fr) => (
              <tr key={fr.id}>
                <TableCell>
                  <a href={adminQuestionPath({ questionId: fr.question.id })}>
                    {truncatedQuestion(fr.question.question)}
                  </a>
                </TableCell>
                <TableCell>
                  <Link to={adminUserUpdatePath({ userId: fr.requester.id })}>
                    {fr.requester.name}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    to={adminQuestionRequestPath({
                      questionId: fr.question.id,
                      requestId: fr.questionRequest.id,
                    })}
                  >
                    {fr.responder.name}
                  </Link>
                </TableCell>
                <TableCell>{fr.requesterMessage}</TableCell>
                <TableCell>{fr.requesterAvailability}</TableCell>
                <TableCell>{fr.createdAt}</TableCell>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

const ADMIN_FOLLOWUP_REQUESTS_QUERY_DOCUMENT = graphql(`
  query AdminFollowupRequestsRoute {
    adminFollowupRequests {
      nodes {
        id
        requesterMessage
        requesterAvailability
        createdAt
        questionRequest {
          id
        }
        question {
          id
          question
        }
        responder {
          id
          name
        }
        requester {
          id
          name
        }
      }
    }
  }
`)
