import {
  QuestionResponseRatingEnum,
  QuestionStateEnum,
  UserStatusEnum,
} from "~/__generated__/graphql"

export const parseResponseRating = (status: QuestionResponseRatingEnum) => {
  switch (status) {
    case QuestionResponseRatingEnum.Good:
      return "great"
    case QuestionResponseRatingEnum.Neutral:
      return "good"
    case QuestionResponseRatingEnum.Bad:
      return "not good"
    default:
      return "rating unknown"
  }
}

export const parseQuestionState = (state: QuestionStateEnum) => {
  switch (state) {
    case QuestionStateEnum.Submitted:
      return "waiting for review"
    case QuestionStateEnum.Open:
      return "open"
    case QuestionStateEnum.Closed:
      return "closed"
    case QuestionStateEnum.AdminRejected:
      return "rejected"
    default:
      return "unknown"
  }
}

export const parseUserStatus = (status: UserStatusEnum) => {
  switch (status) {
    case UserStatusEnum.New:
      return "pending review"
    case UserStatusEnum.Active:
      return "active"
    case UserStatusEnum.AdminRejected:
      return "rejected"
    default:
      return "status unknown"
  }
}
