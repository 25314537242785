import { Link } from "react-router-dom"
import { graphql } from "~/__generated__"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import {
  adminQuestionPath,
  adminUserUpdatePath,
  adminQuestionRequestPath,
} from "~/util/paths"
import { truncatedQuestion } from "~/util/questions"
import { TableCell } from "~/components/Admin/TableCell"
import { ApproveResponseAction } from "~/components/Admin/ApproveResponseAction"

export const AdminResponsesRoute = () => {
  const result = useGraphQLQuery(ADMIN_RESPONSES_QUERY_DOCUMENT)

  if (result.status === "error") return <div>error</div>
  if (result.status === "loading") return <div>loading …</div>
  console.log(result.data)
  const { nodes: responses } = result.data.adminResponses

  return (
    <div>
      <h1>New Question Responses</h1>
      {responses.length < 1 ? (
        <p className="mt-8">
          There are no responses that require moderation at this time.
        </p>
      ) : (
        <div className="overflow-x-auto mt-8">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <TableCell heading>Question</TableCell>
                <TableCell heading>Responder</TableCell>
                <TableCell heading>From</TableCell>
                <TableCell heading>Reponse</TableCell>
                <TableCell heading>Responded at</TableCell>
                <TableCell heading>1:1</TableCell>
                <TableCell heading>Actions</TableCell>
              </tr>
            </thead>
            <tbody>
              {responses.map((response) => (
                <tr key={response.id}>
                  <TableCell className="md:min-w-[200px]">
                    <Link
                      to={adminQuestionPath({
                        questionId: response.question.id,
                      })}
                      className="line-clamp-2"
                    >
                      {truncatedQuestion(response.question.question)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={adminUserUpdatePath({
                        userId: response.responder.id,
                      })}
                    >
                      {response.responder.name}
                    </Link>
                  </TableCell>
                  <TableCell>{response.from}</TableCell>
                  <TableCell className="md:max-w-2xl">
                    <div className="line-clamp-2">{response.responseText}</div>
                  </TableCell>
                  <TableCell>{response.respondedAt}</TableCell>
                  <TableCell>
                    {response.openToOneOnOne ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={adminQuestionRequestPath({
                        questionId: response.question.id,
                        requestId: response.id,
                      })}
                    >
                      view
                    </Link>{" "}
                    |{" "}
                    <ApproveResponseAction
                      requestId={response.id}
                      requestState={response.state}
                      onSuccess={() => result.refetch()}
                    />
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

const ADMIN_RESPONSES_QUERY_DOCUMENT = graphql(`
  query AdminResponsesRoute {
    adminResponses {
      nodes {
        id
        responseText
        respondedAt
        from
        state
        privacyStatus
        openToOneOnOne
        question {
          id
          question
        }
        responder {
          id
          name
        }
      }
    }
  }
`)
