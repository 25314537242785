import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { QuestionResponseRatingEnum } from "~/__generated__/graphql"
import { useGraphQLMutation } from "~/graphql/graphql-hook"

interface RateResponseProps {
  questionRequestId: string
  label: string
  rating: QuestionResponseRatingEnum
  onSuccess?: () => void
}

export const RateResponse = ({
  questionRequestId,
  rating,
  label,
  onSuccess,
}: RateResponseProps) => {
  const rateMutation = useGraphQLMutation(RATE_RESPONSE_MUTATION)

  const rateResponse = () => {
    rateMutation.mutate(
      {
        input: {
          questionRequestId,
          rating,
        },
      },
      {
        onSuccess: () => {
          toast.success("Response rated")
          if (onSuccess) onSuccess()
        },
      },
    )
  }

  return (
    <button
      type="button"
      className="underline hover:text-pink-500"
      onClick={rateResponse}
    >
      {label}
    </button>
  )
}

const RATE_RESPONSE_MUTATION = graphql(`
  mutation RateQuestionResponseMutation($input: RateQuestionResponseInput!) {
    rateQuestionResponse(input: $input) {
      questionRequest {
        id
      }
    }
  }
`)
