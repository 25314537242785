import { graphql } from "~/__generated__"
import { useCurrentUserMaybe } from "~/components/ViewerContext"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import { newQuestionPath, questionPath } from "~/util/paths"
import { truncatedQuestion } from "~/util/questions"

export const QUESTIONS_ROUTE_DOCUMENT = graphql(`
  query QuestionsRoute {
    questions {
      nodes {
        id
        question
        closedAt
      }
    }
  }
`)

export const QuestionsRoute = () => {
  const { currentUser } = useCurrentUserMaybe()
  const result = useGraphQLQuery(QUESTIONS_ROUTE_DOCUMENT)

  if (result.status === "error") return <div>error</div>
  if (result.status === "loading" || !currentUser) return <div>loading …</div>

  const { questions } = result.data

  if (questions.nodes.length === 0) {
    return currentUser?.canAskQuestions ? (
      <div className="text-2xl text-center">
        You have not asked any questions yet.
        <br />
        &gt;{" "}
        <a href={newQuestionPath}>
          <i>Ask your first question</i>
        </a>
      </div>
    ) : (
      <div className="text-center">
        <div className="text-2xl mb-4">Thanks for creating an account.</div>
        An admin will review your account and authorize you to ask questions.
      </div>
    )
  }

  return (
    <div className="max-w-3xl m-auto">
      <div className="sm:flex items-baseline justify-between border-b pb-8">
        <h1>Your questions</h1>
        {currentUser?.canAskQuestions && (
          <a href={newQuestionPath}>+ New question</a>
        )}
      </div>
      <div className="mt-4">
        {questions.nodes.map((q) => (
          <div className="py-4" key={q.id}>
            <a
              href={questionPath({ questionId: q.id })}
              className="inline-block"
            >
              {truncatedQuestion(q.question)}
            </a>
            <div className="text-sm text-gray-400">
              {q.closedAt ? "Closed" : "Open"}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
