import { UseQueryResult } from "@tanstack/react-query"
import React, { createContext, useContext, useMemo } from "react"
import { AdminQuestionLayoutQuery } from "~/__generated__/graphql"

interface AdminQuestionContextType {
  questionResult: UseQueryResult<AdminQuestionLayoutQuery>
}

const AdminQuestionContext = createContext<AdminQuestionContextType | null>(
  null,
)

export const AdminQuestionProvider = ({
  questionResult,
  children,
}: {
  questionResult: UseQueryResult<AdminQuestionLayoutQuery>
  children: React.ReactNode
}) => {
  const value = useMemo(() => ({ questionResult }), [questionResult])

  return (
    <AdminQuestionContext.Provider value={value}>
      {children}
    </AdminQuestionContext.Provider>
  )
}

export const useAdminQuestion = () => {
  const contextValue = useContext(AdminQuestionContext)
  if (contextValue === null) {
    throw Error("Context has not been Provided!")
  }
  return contextValue
}
