import { useParams } from "react-router-dom"
import { Formik, Form, FormikProps, FormikHelpers } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { useGraphQLMutation, useGraphQLQuery } from "~/graphql/graphql-hook"
import { Fieldset } from "~/components/Fieldset"
import { TextField } from "~/components/FormFields/TextField"
import { displayErrors } from "~/util/validations"
import { Button } from "~/components/Button"
import { Breadcrumb } from "~/components/Breadcrumb"
import { questionPath } from "~/util/paths"
import { assert } from "~/util/assert"

type FollowupRequestValues = {
  requesterMessage: string
  requesterAvailability: string
}

export const FollowupRequestRoute = () => {
  const { questionRequestId } = useParams()
  assert(questionRequestId)

  const questionRequestQuery = useGraphQLQuery(
    QUESTION_REQUEST_QUERY_DOCUMENT,
    { id: questionRequestId },
  )
  const mutation = useGraphQLMutation(FOLLOWUP_REQUEST_MUTATION)

  if (questionRequestQuery.status === "error") return <div>error</div>
  if (questionRequestQuery.status === "loading") return <div>loading …</div>

  const { questionRequest } = questionRequestQuery.data

  const validationSchema = Yup.object({
    requesterMessage: Yup.string().required("A message is required"),
    requesterAvailability: Yup.string().required(
      "Your availability is required",
    ),
  })

  const initialValues = {
    requesterMessage: "",
    requesterAvailability: "",
  }

  const onSubmit = (
    values: FollowupRequestValues,
    { setSubmitting, setFieldError }: FormikHelpers<FollowupRequestValues>,
  ) => {
    console.log(values)

    mutation.mutate(
      {
        input: {
          questionRequestId,
          ...values,
        },
      },
      {
        onError: (error) => displayErrors(error, setFieldError),
        onSuccess: () => {
          toast.success("Followup request submitted")
          window.location.pathname = questionPath({
            questionId: questionRequest.question.id,
          })
        },
        onSettled: () => setSubmitting(false),
      },
    )
  }

  return (
    <div className="max-w-3xl m-auto">
      <Breadcrumb
        path={questionPath({ questionId: questionRequest.question.id })}
        label="Question"
      />
      <h1 className="mt-6">Schedule 1:1 with {questionRequest.from}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }: FormikProps<FollowupRequestValues>) => (
          <Form className="mt-8">
            <Fieldset legend="Request details">
              <TextField
                as="textarea"
                label="Your message"
                name="requesterMessage"
                rows={4}
              />
              <TextField
                as="textarea"
                label="When can you talk?"
                name="requesterAvailability"
                rows={4}
              />
            </Fieldset>
            <div className="mt-8">
              <Button
                type="submit"
                label="Request 1:1"
                disabled={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const QUESTION_REQUEST_QUERY_DOCUMENT = graphql(`
  query QuestionRequestDocument($id: ID!) {
    questionRequest(id: $id) {
      id
      from
      question {
        id
      }
    }
  }
`)

const FOLLOWUP_REQUEST_MUTATION = graphql(`
  mutation CreateFollowupRequest($input: CreateFollowupRequestInput!) {
    createFollowupRequest(input: $input) {
      followupRequest {
        id
      }
    }
  }
`)
