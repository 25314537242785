import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import {
  settingsPath,
  questionsPath,
  newQuestionPath,
  questionPath,
  respondPath,
  followupRequestPath,
  pickSuggestionsPath,
  adminQuestionsPath,
  adminPath,
  adminUsersPath,
  adminUserUpdatePath,
  adminUserCreatePath,
  adminQuestionPath,
  adminQuestionRespondentsPath,
  adminQuestionRequestPath,
  adminQuestionFollowupRequestsPath,
  adminFollowupRequestsPath,
  adminResponsesPath,
} from "~/util/paths"
import { ErrorBoundary } from "./ErrorBoundary"
import { SettingsRoute } from "./SettingsRoute"
import { QuestionResponseRoute } from "./QuestionResponseRoute"
import { FollowupRequestRoute } from "./FollowupRequestRoute"
import { QuestionsRoute } from "./QuestionsRoute"
import { QuestionRoute } from "./QuestionRoute"
import { NewQuestionRoute } from "./NewQuestionRoute"
import { PickSuggestionsRoute } from "./PickSuggestionsRoute"
import { AdminQuestionsRoute } from "./admin/AdminQuestionsRoute"
import { AdminLayout } from "./admin/AdminLayout"
import { AdminUsersRoute } from "./admin/AdminUsersRoute"
import { AdminUserCreateRoute } from "./admin/AdminUserCreateRoute"
import { AdminUserUpdateRoute } from "./admin/AdminUserUpdateRoute"
import { AdminQuestionLayout } from "./admin/AdminQuestionLayout"
import { AdminQuestionOverviewRoute } from "./admin/AdminQuestionOverviewRoute"
import { AdminQuestionRespondentsRoute } from "./admin/AdminQuestionRespondentsRoute"
import { AdminQuestionRequestRoute } from "./admin/AdminQuestionRequestRoute"
import { AdminQuestionFollowupRequestsRoute } from "./admin/AdminQuestionFollowupRequestsRoute"
import { AdminFollowupRequestsRoute } from "./admin/AdminFollowupRequestsRoute"
import { AdminResponsesRoute } from "./admin/AdminResponsesRoute"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route path={settingsPath} element={<SettingsRoute />} />
      <Route path={questionsPath} element={<QuestionsRoute />} />
      <Route path={newQuestionPath} element={<NewQuestionRoute />} />
      <Route
        path={pickSuggestionsPath.pattern}
        element={<PickSuggestionsRoute />}
      />
      <Route path={questionPath.pattern} element={<QuestionRoute />} />
      <Route path={respondPath.pattern} element={<QuestionResponseRoute />} />
      <Route
        path={followupRequestPath.pattern}
        element={<FollowupRequestRoute />}
      />
      <Route path={adminPath} element={<AdminLayout />}>
        <Route path={adminQuestionsPath}>
          <Route
            element={<AdminQuestionLayout />}
            path={adminQuestionPath.pattern}
          >
            <Route
              path={adminQuestionRespondentsPath.pattern}
              element={<AdminQuestionRespondentsRoute />}
            />
            <Route
              path={adminQuestionRequestPath.pattern}
              element={<AdminQuestionRequestRoute />}
            />
            <Route
              path={adminQuestionFollowupRequestsPath.pattern}
              element={<AdminQuestionFollowupRequestsRoute />}
            />
            <Route index element={<AdminQuestionOverviewRoute />} />
          </Route>

          <Route index element={<AdminQuestionsRoute />} />
        </Route>
        <Route path={adminUsersPath} element={<AdminUsersRoute />} />
        <Route path={adminUserCreatePath} element={<AdminUserCreateRoute />} />
        <Route
          path={adminUserUpdatePath.pattern}
          element={<AdminUserUpdateRoute />}
        />
        <Route
          path={adminFollowupRequestsPath}
          element={<AdminFollowupRequestsRoute />}
        />
        <Route path={adminResponsesPath} element={<AdminResponsesRoute />} />
        <Route index element={<Navigate replace to={adminQuestionsPath} />} />
      </Route>
    </Route>,
  ),
)
