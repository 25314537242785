import { graphql } from "~/__generated__"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import { adminQuestionPath } from "~/util/paths"
import { TableCell } from "~/components/Admin/TableCell"
import { truncatedQuestion } from "~/util/questions"
import { parseQuestionState } from "~/util/parseEnums"

const ADMIN_QUESTIONS_QUERY_DOCUMENT = graphql(`
  query AdminQuestionsRoute {
    adminQuestions {
      nodes {
        id
        question
        createdAt
        state
      }
    }
  }
`)

export const AdminQuestionsRoute = () => {
  const result = useGraphQLQuery(ADMIN_QUESTIONS_QUERY_DOCUMENT)

  if (result.status === "error") return <div>error</div>
  if (result.status === "loading") return <div>loading …</div>

  if (result.data.adminQuestions.nodes.length === 0)
    return (
      <div className="text-2xl text-center">
        No questions have been asked yet.
      </div>
    )

  return (
    <div className="overflow-x-auto mt-8">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <TableCell heading>Question</TableCell>
            <TableCell heading>Status</TableCell>
            <TableCell heading>Created</TableCell>
          </tr>
        </thead>
        <tbody>
          {result.data.adminQuestions.nodes.map((q) => (
            <tr key={q.id}>
              <TableCell>
                <a href={adminQuestionPath({ questionId: q.id })}>
                  {truncatedQuestion(q.question)}
                </a>
              </TableCell>
              <TableCell>{parseQuestionState(q.state)}</TableCell>
              <TableCell>{q.createdAt}</TableCell>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
