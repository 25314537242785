/**
 * typescript aware assert. nicer than throwing an invariant
 *
 * via https://twitter.com/buildsghost/status/1374461243499507717
 */
export function assert(value: boolean, message?: string): asserts value
export function assert<T>(
  value: T | null | undefined,
  message?: string,
): asserts value is T
export function assert(value: unknown, message?: string) {
  if (value === false || value == null) {
    throw new Error(
      message != null ? `Assertion failed: ${message}` : `Assertion failed`,
    )
  }
}

/**
 * support exhaustive checks, e.g. make sure all switch cases are handled
 *
 * @see https://github.com/aikoven/assert-never
 */
export function assertNever(value: never, message?: string): never {
  throw new Error(
    `Unexpected assert never: ${JSON.stringify(
      message == null ? value : `${value} ${message}`,
    )}`,
  )
}
