import clsx from "clsx"
import toast from "react-hot-toast"
import { graphql } from "~/__generated__"
import { UserStatusEnum } from "~/__generated__/graphql"
import { useGraphQLQuery, useGraphQLMutation } from "~/graphql/graphql-hook"
import { TableCell } from "~/components/Admin/TableCell"
import { adminUserUpdatePath, adminUserCreatePath } from "~/util/paths"
import { displayErrors } from "~/util/validations"
import { parseUserStatus } from "~/util/parseEnums"

export const AdminUsersRoute = () => {
  const result = useGraphQLQuery(ADMIN_USERS_QUERY_DOCUMENT)
  const userStatusMutation = useGraphQLMutation(UPDATE_USER_STATUS_MUTATION)

  if (result.status === "error") return <div>error</div>
  if (result.status === "loading") return <div>loading …</div>

  if (result.data.adminUsers.nodes.length === 0)
    return (
      <div className="text-2xl text-center">
        We do not have any users yet :(
      </div>
    )

  const updateUserStatus = ({
    userId,
    status,
  }: {
    userId: string
    status: UserStatusEnum
  }) => {
    userStatusMutation.mutate(
      {
        input: {
          userId,
          status,
        },
      },
      {
        onSuccess: () => toast.success(`User ${parseUserStatus(status)}`),
        onError: (error) => displayErrors(error),
        onSettled: () => result.refetch(),
      },
    )
  }

  return (
    <>
      <div className="sm:flex items-baseline justify-between">
        <h1>Users</h1>
        <a className="inline-block" href={adminUserCreatePath}>
          + Add new user
        </a>
      </div>
      <div className="overflow-x-auto mt-8">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <TableCell heading>Name</TableCell>
              <TableCell heading hideOnMobile>
                Email
              </TableCell>
              <TableCell heading hideOnMobile>
                Company name
              </TableCell>
              <TableCell heading hideOnMobile>
                Status
              </TableCell>
              <TableCell heading>Actions</TableCell>
            </tr>
          </thead>
          <tbody>
            {result.data.adminUsers.nodes.map((u) => {
              const pendingReview =
                u.status === UserStatusEnum.New ||
                u.status === UserStatusEnum.Respondent
              const rejected = u.status === UserStatusEnum.AdminRejected
              return (
                <tr
                  key={u.id}
                  className={clsx(
                    pendingReview && "bg-blue-200/50",
                    rejected && "bg-red-200/50",
                  )}
                >
                  <TableCell>
                    {u.name}{" "}
                    {u.isAdmin && (
                      <small className="text-neutral-500">[admin]</small>
                    )}
                  </TableCell>
                  <TableCell hideOnMobile>{u.email}</TableCell>
                  <TableCell hideOnMobile>{u.companyName}</TableCell>
                  <TableCell hideOnMobile>{u.status}</TableCell>
                  <TableCell>
                    {!rejected && (
                      <a href={adminUserUpdatePath({ userId: u.id })}>edit</a>
                    )}
                    {pendingReview && (
                      <>
                        {" "}
                        |{" "}
                        <button
                          onClick={() =>
                            updateUserStatus({
                              userId: u.id,
                              status: UserStatusEnum.Active,
                            })
                          }
                          className="underline hover:text-green-700"
                        >
                          approve
                        </button>{" "}
                        |{" "}
                        <button
                          onClick={() =>
                            updateUserStatus({
                              userId: u.id,
                              status: UserStatusEnum.AdminRejected,
                            })
                          }
                          className="underline hover:text-red-700"
                        >
                          reject
                        </button>
                      </>
                    )}
                  </TableCell>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const ADMIN_USERS_QUERY_DOCUMENT = graphql(`
  query AdminUsersRoute {
    adminUsers {
      nodes {
        id
        name
        email
        companyName
        isAdmin
        status
      }
    }
  }
`)

const UPDATE_USER_STATUS_MUTATION = graphql(`
  mutation UpdateUserStatus($input: UpdateUserStatusInput!) {
    updateUserStatus(input: $input) {
      user {
        id
      }
    }
  }
`)
