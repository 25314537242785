import { QuestionRequestStateEnum } from "~/__generated__/graphql"
import { ActionButton } from "../ActionButton"
import { graphql } from "~/__generated__"
import { useGraphQLMutation } from "~/graphql/graphql-hook"
import { useState } from "react"
import { displayErrors } from "~/util/validations"

export const ApproveResponseAction = ({
  requestId,
  requestState,
  onSuccess,
}: {
  requestId: string
  requestState: QuestionRequestStateEnum
  onSuccess: () => void
}) => {
  const [loading, setLoading] = useState(false)

  const approveResponseMutation = useGraphQLMutation(
    ADMIN_APPROVE_RESPONSE_DOCUMENT,
  )
  const approveResponse = () => {
    setLoading(true)
    approveResponseMutation.mutate(
      { input: { requestId } },
      {
        onError: (error) => displayErrors(error),
        onSuccess: onSuccess,
        onSettled() {
          setLoading(false)
        },
      },
    )
  }

  if (requestState !== QuestionRequestStateEnum.Responded) return null

  return (
    <ActionButton
      title="Approve"
      onClick={() => approveResponse()}
      disabled={loading}
    />
  )
}

const ADMIN_APPROVE_RESPONSE_DOCUMENT = graphql(`
  mutation AdminApproveResponse($input: AdminApproveResponseInput!) {
    adminApproveResponse(input: $input) {
      questionRequest {
        id
      }
    }
  }
`)
