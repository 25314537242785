import { Outlet, useParams } from "react-router-dom"
import { graphql } from "~/__generated__"
import { AdminQuestionProvider } from "~/components/Admin/AdminQuestionContext"
import { AdminQuestionHeader } from "~/components/Admin/QuestionHeader"
import { useGraphQLQuery } from "~/graphql/graphql-hook"
import { assert } from "~/util/assert"

const ADMIN_QUESTION_QUERY_DOCUMENT = graphql(`
  query AdminQuestionLayout($id: ID!) {
    question(id: $id) {
      ...Question_AdminHeader

      id
      question
      from
      emailSubject
      emailBody
      emailBodyPreview
      requestOneOnOne
      numberOfResponses
      durationInHours
      state
      rejectionNotes
      rejectionSentAt
      createdAt

      user {
        id
        name
      }
    }
  }
`)

export const AdminQuestionLayout = () => {
  const { questionId } = useParams<{ questionId: string }>()
  assert(questionId)

  const result = useGraphQLQuery(ADMIN_QUESTION_QUERY_DOCUMENT, {
    id: questionId,
  })

  if (result.status === "error") return <div>error</div>
  if (result.status === "loading") return <div>loading …</div>

  const { question } = result.data

  return (
    <AdminQuestionProvider questionResult={result}>
      <div className="mt-8">
        <AdminQuestionHeader questionQuery={question} />
        <Outlet />
      </div>
    </AdminQuestionProvider>
  )
}
