import { useParams } from "react-router-dom"
import { questionsPath } from "~/util/paths"
import { Question } from "~/components/Question"

export const QuestionRoute = () => {
  const { questionId } = useParams()

  return (
    <div className="max-w-3xl m-auto">
      <a href={questionsPath} className="inline-block mb-6">
        &lt; Questions
      </a>
      {questionId && <Question questionId={questionId} />}
    </div>
  )
}
