import clsx from "clsx"

interface FieldErrorProps {
  message: string
  className?: string
}

export const FieldError = ({ message, className }: FieldErrorProps) => {
  return <div className={clsx("text-red-700", className)}>{message}</div>
}
